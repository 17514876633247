import { ENCRYPTS } from '../../config/flavor';
import CryptoJS from 'crypto-js';
const getConfigKey = () => {
    const iv = process.env.REACT_APP_IV;
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    return {
        secretKey: secretKey,
        iv: parseInt(iv)
    };
};
/**
 * Encrypts the provided data object using AES encryption with a randomly generated IV.
 * 
 * @async
 * @function encryptData
 * @param {Object} object - The data object to be encrypted.
 * @returns {Promise<Object>} - A promise that resolves to an object containing the ciphertext and IV, or the original object if encryption is disabled or an error occurs.
 * 
 * @example
 * const data = { cpf_cliente: '12345678900', recaptcha_token: 'exampleToken' };
 * encryptData(data).then(encryptedData => {
 *     console.log(encryptedData);
 * });
 */
const encrypyData = async (object) => {
    try {
        const data = object;
        if (!ENCRYPTS?.isEnable) {
            return object;
        }
        const { secretKey, iv: randomIv } = getConfigKey();
        const iv = CryptoJS.lib.WordArray.random(randomIv); // Gera um IV aleatório
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), CryptoJS.enc.Utf8.parse(secretKey), {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return {
            payload: encrypted.ciphertext.toString(CryptoJS.enc.Base64),
            iv: iv.toString(CryptoJS.enc.Base64)
        };
    } catch (error) {
        console.log(error);
        return object;
    }
};
export {
    encrypyData,
};