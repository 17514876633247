import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import Body from './body';
import { formContext } from '../../../context/formcontext';
import { requestContext } from '../../../context/requestContext';
import NSU from './nsu';
import { Link, useNavigate } from 'react-router-dom';
import { messagesToUser, pathViews } from '../../../constants';
import { FaInfoCircle } from '../../../assets/11ponto11/icons';
import { RenderButtonClick } from './renderButtonSubmit';
import ModalParameters from '../../../components/modal/parameters';

const Proposal = () => {
    const { handleForm, state: { faixa,
        cpf_cliente,
        nsu,
        id_convenio,
        celular,
        cdBanco,
        cdAgencia,
        cdConta,
        email,
        tipoConta,
        coberturas
    } } = useContext(formContext);
    const navigate = useNavigate();
    const { responseConsultaSaque, fetchEnviarProposta } = useContext(requestContext);
    const [show, setShow] = useState(false);
    const handleOpenModal = () => { setShow(true); };
    const handleClose = () => { setShow(false); };
    useEffect(() => {
        handleForm({
            tipoConta: 1
        });
        const controlSetItems = () => {
            if (responseConsultaSaque) {
                const {
                    nsu,
                    dataBase,
                    numeroParcelas,
                    valorFinanciado,
                    valorParcela,
                    valorPrincipal,
                    valorBruto,
                    valorLiquido,
                    valorIOC,
                    taxaCliente,
                    taxaAP,
                    taxaCET,
                    taxaNominal,
                    data1Vcto,
                    dataUltVcto,
                    taxaCETMes,
                    taxaClienteAno,
                    parametros
                } = responseConsultaSaque;
                if (parametros?.length) {
                    if (parametros?.mensagem?.length) {
                        const { mensagem } = parametros.mensagem[parametros?.mensagem?.length - 1];
                        if (mensagem === messagesToUser.messageNotBenefits) {
                            return;
                        }
                    }
                    const sortParametros = parametros.sort((prev, next) => prev.faixa - next.faixa);
                    const firstParametros = sortParametros[0];
                    handleForm({
                        ...firstParametros
                    });
                }
                handleForm({
                    nsu,
                    dataBase,
                    numeroParcelas,
                    valorFinanciado,
                    valorParcela,
                    valorPrincipal,
                    valorBruto,
                    valorLiquido,
                    valorIOC,
                    taxaCliente,
                    taxaAP,
                    taxaCET,
                    taxaNominal,
                    data1Vcto,
                    dataUltVcto,
                    taxaCETMes,
                    taxaClienteAno,
                });
            }
        };
        controlSetItems();
    }, [responseConsultaSaque]);
    const onSubmit = async ({
        aceitaPctVantagens
    }) => {
        window.scrollTo(0, 0);
        const payload = {
            cpf_cliente,
            nsu,
            id_convenio,
            celular,
            email,
            tipoConta,
            numbanco: cdBanco,
            numAgencia: cdAgencia,
            numConta: cdConta,
            digito: '1',
            aceitavantagens: aceitaPctVantagens,
            designarBenificiario: false,
            cpfBenificiario: '',
            nomeBenificiario: '',
            parenteNome: '',
            nascimentoData: '1966-02-10',
            salario: 0,
            prevenda: true,
            faixaPctVantagens: aceitaPctVantagens ? faixa : false,
        };
        const response = await fetchEnviarProposta(payload);
        if (response) {
            navigate(pathViews.PROPOSAL_SUCCESS);
        }
    };
    const RenderCoberturas = () => {
        if (!coberturas?.length) {
            return <></>;
        }
        return (
            <>
                <Link to={'#'} className='text-decoration-none text-dark mb-4' onClick={handleOpenModal}>
                    Ver as coberturas dessa proposta <FaInfoCircle />
                </Link>
            </>
        );
    };
    return (
        <Container fluid className="mb-5">
            <ModalParameters handleClose={handleClose} show={show} coberturas={coberturas} />
            <div className='d-block d-md-none'>
                <Card className='border-0 rounded'>
                    <Body />
                    <Card.Body>
                        <NSU />
                        <RenderCoberturas />
                        <RenderButtonClick onClick={onSubmit} />
                    </Card.Body>
                </Card>
            </div>
            <div className='d-none d-md-flex'>
                <Row className='align-items-center'>
                    <Col md={6} className='mt-5'>
                        <Card className='border-0 rounded vh-100'>
                            <Card.Body>
                                <Body />
                                <NSU />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <RenderCoberturas />
                        <RenderButtonClick onClick={onSubmit} />
                    </Col>
                </Row>
            </div>
        </Container>
    );
};
export default Proposal;