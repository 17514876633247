const flipImage = {
    MozTransform: 'scaleX(-1)', 
    OTransform: 'scaleX(-1)', 
    WebkitTransform: 'scaleX(-1)', 
    transform: 'scaleX(-1)',
};
const cardImage = {
    maxWidth: 80,
    maxHeight: 100
};
export {
    flipImage,
    cardImage
};
