import React, { useContext, useEffect } from 'react';
import { Card, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { pathViews } from '../../../../constants';
import { formContext } from '../../../../context/formcontext';
import { FaHome } from '../../../../assets/11ponto11/icons';
import { requestContext } from '../../../../context/requestContext';
import { convertToTitleCase } from '../../../../helpers/stringHandless';
const ProposalSuccess = () => {
    const { state: { celular, nome, linkFormalizacao }, handleForm } = useContext(formContext);
    const { responseSucessoEnvioProposta, fetchEnviaMsgWhatsapp } = useContext(requestContext);
    useEffect(() => {
        const sendMessageWhatsapp = () => {
            if (nome && celular) {
                fetchEnviaMsgWhatsapp({
                    celular, nome
                });
            }
        };
        sendMessageWhatsapp();
    }, [nome, celular]);
    useEffect(() => {
        const constrollSetState = () => {
            if (responseSucessoEnvioProposta) {
                handleForm({
                    ...responseSucessoEnvioProposta
                });
            }
        };
        constrollSetState();
    }, [responseSucessoEnvioProposta]);
    const handleLink = () => {
        if (linkFormalizacao) {
            window.open(linkFormalizacao, '_blank').focus();
        }
    };
    const RenderLink = () => (
        <>
            Você está quase lá! Só precisamos de algumas confirmações de segurança!
            <Link to={'#'} onClick={handleLink}>Clique aqui</Link> para prosseguir com a formalização.
        </>
    );
    return (
        <Container fluid className='mt-4'>
            <Card className="bg-dark border-0 d-md-block d-none">
                <Card.Img src={require('../../../../assets/11ponto11/success.png')} alt="Card image" />
                <Card.ImgOverlay className='text-dark bordered-0'>
                    <Card.Title>Proposta para {convertToTitleCase(nome)}</Card.Title>
                    <Card.Text>
                        Enviada com sucesso! {linkFormalizacao ? (
                            <RenderLink />
                        ) : ''} </Card.Text>
                    {linkFormalizacao ? (
                        <Card.Text>
                            Seu comprovante do envio da proposta.
                        </Card.Text>
                    ) : ''}
                    <Card.Text>Voltar para tela <Link className='text-decoration-none text-info me-2' to={pathViews.INDEX}>inicial</Link>
                        <FaHome />
                    </Card.Text>
                </Card.ImgOverlay>
            </Card>
            <Card className='border-0 d-block d-md-none'>
                <Card.Body>
                    <Card.Title>Proposta para {convertToTitleCase(nome)}</Card.Title>
                    <Card.Text>
                        Enviada com sucesso! {linkFormalizacao ? (
                            <RenderLink />
                        ) : ''} </Card.Text>
                    {linkFormalizacao ? (
                        <Card.Text>
                            Seu comprovante do envio da proposta.
                        </Card.Text>
                    ) : ''}
                    <Card.Text>Voltar para tela <Link className='text-decoration-none text-info me-2' to={pathViews.INDEX}>inicial</Link>
                        <FaHome />
                    </Card.Text>
                    <img src={require('../../../../assets/11ponto11/success.png')} alt="Card image" style={{
                        width: '100%',
                        height: '100%'
                    }} />
                </Card.Body>
            </Card>
        </Container>
    );
};
export default ProposalSuccess;