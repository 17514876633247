import React, { useContext, useEffect } from 'react';
import {
    Card, Col, Container, Row,
} from 'react-bootstrap';
import FormDocidentification from '../../../components/form/docidentification';
import Body from './body';
import { People } from '../../../assets/11ponto11/pessoa-em-pe.index';
import { formContext } from '../../../context/formcontext';
import { REACT_APP_RECAPTCHA_KEY } from '../../../config/flavor';

const PeopleIdentification = () => {
    const { handleForm } = useContext(formContext);
    useEffect(() => {
        const loadRecaptcha = () => {
            if (!REACT_APP_RECAPTCHA_KEY) {
                return;
            }
            const script = document.createElement('script');
            script.src = `https://www.google.com/recaptcha/api.js?render=${REACT_APP_RECAPTCHA_KEY}`;
            document.body.appendChild(script);
        };
        loadRecaptcha();
    }, [REACT_APP_RECAPTCHA_KEY]);
    useEffect(() => {
        handleForm({
            phases: 1,
            idConvenio: null,
        });
    }, []);
    useEffect(() => {
        const body = document.getElementById('root');
        body.className = '';
        body.style.backgroundColor = '#ffff';
    }, []);
    return (
        < Container fluid>
            <div className='d-block d-md-none'>
                <Card className='border-0 rounded'>
                    <Card.Body>
                        <Body />
                        {REACT_APP_RECAPTCHA_KEY ? (
                            <>
                                <FormDocidentification />
                                <div
                                    className="g-recaptcha"
                                    data-sitekey="_reCAPTCHA_site_key_"
                                    data-size="invisible"
                                ></div>
                            </>
                        ) : (
                            <FormDocidentification />
                        )}
                    </Card.Body>
                </Card>
            </div>
            <div className='d-none d-md-flex'>
                <Row className='align-items-center'>
                    <Col md={5} className='vh-100 mx-auto text-center d-flex align-items-center  justify-content-center flex-column'>
                        <People className='img-responsive' />
                    </Col>
                    <Col md={7} >
                        <Card className='border-0'>
                            <Card.Body>
                                <Body />
                                {REACT_APP_RECAPTCHA_KEY ? (
                                    <>
                                        <FormDocidentification />
                                        <div
                                            className="g-recaptcha"
                                            data-sitekey="_reCAPTCHA_site_key_"
                                            data-size="invisible"
                                        ></div>
                                    </>
                                ) : (
                                    <FormDocidentification />
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Container >
    );
};
export default PeopleIdentification;
