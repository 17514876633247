import {
    API_URL_TEST,
    API_URL_HOMOL,
    API_URL_PRODUCTION,
    URL_FILE_DEVELOP,
    API_URL_DEVELOP,
    URL_FILE_TEST,
    SEND_GA_ID,
} from '../config/flavor';
import { ENV } from '../config';
import Package from '../../package.json';
import { recoverSingleCache } from '../helpers/cacheHandless';
import { namesCaches } from '../constants';
import { getMyIP } from '../helpers/myIP';
import { generateToken } from '../helpers/recaptcha';
export const getBaseUrl = () => {
    switch (ENV) {
    case 'develop': {
        return formatURL(API_URL_DEVELOP);
    }

    case 'test': {
        return formatURL(API_URL_TEST);
    }

    case 'homol': {
        return formatURL(API_URL_HOMOL);
    }

    case 'production': {
        return formatURL(API_URL_PRODUCTION);
    }

    default:
        return formatURL('');
    }
};

export const getBaseHost = () => {
    switch (ENV) {
    case 'develop': {
        return API_URL_DEVELOP;
    }

    case 'test': {
        return API_URL_TEST;
    }

    case 'homol': {
        return API_URL_HOMOL;
    }

    case 'production': {
        return API_URL_PRODUCTION;
    }

    default:
        return '';
    }
};

export const getBaseUrlLink = () => {
    switch (ENV) {
    case 'develop': {
        return API_URL_DEVELOP.replace(':8000', ':3000');
    }

    case 'test': {
        return API_URL_TEST;
    }

    case 'homol': {
        return API_URL_HOMOL;
    }

    case 'production': {
        return API_URL_PRODUCTION;
    }

    default:
        return '';
    }
};

export const getEnvironment = () => {
    switch (ENV) {
    case 'develop': {
        return 'Desenvolvimento';
    }

    case 'test': {
        return 'Teste';
    }

    case 'homol': {
        return 'Homologação';
    }

    case 'production': {
        return 'Produção';
    }

    default:
        return '';
    }
};

export const getBaseUrlFile = () => {
    if (ENV === 'develop') {
        return URL_FILE_DEVELOP;
    }

    if (ENV === 'test') {
        return URL_FILE_TEST;
    }

    return '';
};

const formatURL = rawUrl => `${rawUrl}`;
export const getVersion = () => {
    const { version } = Package;
    return String(version).slice(0, 3);
};

export const headerOptions = async (uri, method, body) => {
    const token = await generateToken();
    const ga_id = recoverSingleCache(namesCaches.GA_ID);
    let headers = {
        'Content-Type': 'application/json',
        'Ga-Id': ''
    };
    if (token) {
        headers = {
            'Content-Type': 'application/json',
            'Recaptcha-Token': `${String(token)}`,
        };
    }
    if (SEND_GA_ID && token) {
        headers = {
            'Content-Type': 'application/json',
            'Recaptcha-Token': `${String(token)}`,
            'Ga-Id': ga_id
        };
    }
    if (String(method).toLocaleLowerCase() && String(method).toUpperCase() === 'GET') {
        ENV === 'develop' && console.log({
            url: `${getBaseUrl()}${uri}`,
            options: {
                method,
                headers,
            },
        });
        return {
            url: `${getBaseUrl()}${uri}`,
            options: {
                method,
                headers,
            },
        };
    }
    ENV === 'develop' && console.log({
        url: `${getBaseUrl()}${uri}`,
        options: {
            method,
            headers,
            body: JSON.stringify(body)
        },
    });
    if (body) {
        body.remote_ip = await getMyIP();
    }
    return {
        url: `${getBaseUrl()}${uri}`,
        options: {
            method,
            headers,
            body: JSON.stringify(body),
        },
    };
};
