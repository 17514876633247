import React, { useContext } from 'react';
import { formContext } from '../../../context/formcontext';
import { Card } from 'react-bootstrap';
const Phases = () => {
    const { state: { phases } } = useContext(formContext);
    return (
        <Card.Text>
            Passo {phases} de 4
        </Card.Text>
    );
};
export default Phases;