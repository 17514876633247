import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { formContext } from '../../../context/formcontext';
import { convertToTitleCase } from '../../../helpers/stringHandless';
import { Card } from 'react-bootstrap';
import { FLAVOR } from '../../../config/flavor';
function ModalPolices({
    handleClose,
    show,
}) {
    const { state: { nome } } = useContext(formContext);
    return (
        <Modal show={show} onHide={handleClose}>
            {nome && (
                <Modal.Header closeButton>
                    <Modal.Title className='text-capitalize'>Proposta para {convertToTitleCase(nome)}</Modal.Title>
                </Modal.Header>
            )}
            <Modal.Body>
                <Card.Text className='text-left'>
                    USUÁRIOS dos Sites de nossas empresas: www.11ponto11.com.br, www.seucreditonoclick.com.br, www.seuseguronoclick.com.br, www.suaviagemnoclick.com.br, www.meubanconoclick.com.br e do blog que somos patrocinadores exclusivos: www.blogdosrsiape.com.
                </Card.Text>
                <Card.Text className='text-left'>
                    A {FLAVOR} tem o compromisso com a privacidade e a segurança de seus clientes durante todo o processo de navegação e captações de informações dos clientes nos Sites. Os dados cadastrais dos clientes, incluso e-mails não são vendidos, trocados ou divulgados para terceiros, exceto quando essas informações são necessárias para o processo de envio de contrato ou contratação de crédito/seguros. Seus dados pessoais são peça fundamental para que sua solicitação seja atendida e concretizada em um tempo reduzido.
                </Card.Text>
                <Card.Text className='text-left'>
                    Ao fornecer dados e informações pessoais, o USUÁRIO se declara ciente da abertura de cadastro em seu nome, servindo tal ciência de notificação prévia, sendo dispensável qualquer outra comunicação ao consumidor, como a prevista no parágrafo segundo, do artigo 43, do Código de Proteção e Defesa do Consumidor, ou no inciso I, do artigo 1º, da Portaria nº 5/02, da Secretaria de Direito Econômico.
                </Card.Text>
                <Card.Text className='text-left'>
                    Embora a 11PONTO11 empregue seus melhores esforços para a proteção dos dados do USUÁRIO, e considerando as características dos sistemas informáticos, e o constante avanço das técnicas de invasão, A 11PONTO11 NÃO GARANTE a segurança absoluta de seus sistemas e sistemas de terceiros, utilizados pela 11PONTO11, não se responsabilizando por atos de terceiros que eventualmente logrem êxito em coletar e utilizar, por quaisquer meios, dados e/ou informações disponibilizadas pelo USUÁRIO à 11PONTO11.
                </Card.Text>
                <Card.Text className='text-left'>
                    Ao fornecer dados e informações pessoais, durante qualquer interação com o SITE:
                </Card.Text>
                <Card.Text className='text-left'>
                    O USUÁRIO está ciente da utilização dos dados e informações fornecidos à 11PONTO11 para fins contratuais. Ademais, consente que a 11PONTO11 compartilhe tais dados e informações com outra(s) empresa(s) de seu grupo econômico e/ou com parceiros comerciais da 11PONTO11, desde que este compartilhamento de dados seja necessário ao desenvolvimento da relação contratual estabelecida;
                    O USUÁRIO declara estar ciente de que poderá ser necessário à 11PONTO11 fornecer os dados e informações do USUÁRIO às empresas terceiras envolvidas, quando o usuário efetua cotações e contratações de seguros por meio do SITE, que deles necessitam na fase pré e seguinte à contratação, em especial às COMPANHIAS SEGURADORAS, consentindo expressamente o USUÁRIO com o fornecimento de seus dados e informações pessoais nestas circunstâncias;
                    Em particular, no site: www.meubanconoclick.com.br, o cliente fornece seu CPF e o Site busca em sua base cadastral a Agência e número de conta corrente no Banco do Brasil. Se, estiverem corretas, o usuário CONFIRMA ou ALTERA para fazermos a pesquisa de sugestões de linhas de Crédito Disponíveis no BB. A Operação de crédito somente será concretizada após a Identificação Positiva do USUÁRIO e autorização para a contratação, efetuada no ambiente interno do Banco e sem nossa interferência.
                </Card.Text>
                <Card.Text className='text-left'>
                    Não pedimos senhas pessoais, de Internet Banking ou Bancárias de nossos USUÁRIOS.
                </Card.Text>
                <Card.Text className='text-left'>
                    Tendo em vista a natureza dos contratos transacionados pela 11PONTO11, o USUÁRIO consente com a utilização dos dados e informações fornecidos a ela para fins de recebimento de comunicações da 11PONTO11.
                    O USUÁRIO fica ciente que, sempre que fundado em indício de ato ilícito, e requerido por qualquer autoridade, por exemplo, policial, informações sobre a propriedade de um registro do usuário no PORTAL, será lícito à 11PONTO11 fornecer os dados cadastrais do USUÁRIO, a seu exclusivo critério, sem que tal ato constitua qualquer violação de sigilo.
                </Card.Text>
                <Card.Text className='text-left'>
                    Dentro do SITE, é possível que o USUÁRIO encontre links e atalhos que o direcionarão para sites de terceiros. O USUÁRIO declara estar ciente que a 11PONTO11 não tem controle ou ingerência sobre esses sites de terceiros, por não possuírem qualquer ligação com a 11PONTO11, que não tem conhecimento das políticas e regulamentos de privacidade destes sites, que podem ser constantemente alterados, inviabilizando sua verificação prévia pela 11PONTO11. Assim, constitui obrigação do USUÁRIO a verificação dos termos relativos à privacidade destes sites de terceiros, para os quais haja links e atalhos disponíveis nos SITES da 11PONTO11, quando do acesso a eles.
                </Card.Text>
                <Card.Text className='text-left'>
                    Excetuando, os Sites pertencentes ao GRUPO 11PONTO11 que estão cobertos por esta Política de Privacidade. São eles:
                </Card.Text>
                <Card.Text className='text-left'>
                    www.11ponto11.com.br, www.seucreditonoclick.com.br, www.seuseguronoclick.com.br, www.suaviagemnoclick.com.br, www.meubanconoclick.com.br
                </Card.Text>
                <Card.Text className='text-left'>
                    A 11PONTO11 fica isenta de qualquer responsabilidade decorrente de violações da privacidade do USUÁRIO pelos sites que eventualmente possuam link a partir do site da 11PONTO11, excetuando-se, os Sites descritos acima.
                </Card.Text>
                <Card.Text className='text-left'>
                    Caso o USUÁRIO tenha qualquer dúvida referente à privacidade de seus dados, ou o presente regulamento, poderá direcionar suas questões ao E-MAIL: contato@11ponto11.com.br.
                </Card.Text>
                <Card.Text className='text-left'>
                    Devido à rápida evolução das tecnologias na Internet, à 11PONTO11 reserva-se o direito de, a seu exclusivo critério, a qualquer tempo e sem aviso prévio, modificar ou alterar este contrato, razão pela qual se recomenda ao USUÁRIO, quando for utilizar os serviços da 11PONTO11, que proceda à leitura das condições vigentes à época de seu acesso, vez que, a cada acesso , um novo contrato é celebrado pelo USUÁRIO. A utilização dos serviços da 11PONTO11 pelo USUÁRIO através do SITE implicará na irrestrita aceitação das condições vigentes, incluindo as alterações que porventura venham a ser realizadas.
                </Card.Text>
                <Card.Text>
                    O USUÁRIO compreende e autoriza que a 11PONTO11 utilize artifícios técnicos conhecidos como “cookies”, ou semelhantes, para DIRECIONÁ-LO para outros Sites, próprios ou não, devidamente “logado”.
                </Card.Text>
                <Card.Text>
                    O USUÁRIO declara estar ciente que existe a possibilidade de configurar seu navegador para ser avisado, na tela do computador, sobre a recepção dos “cookies”, e para impedir a sua instalação no disco rígido. As informações pertinentes a esta configuração estão disponíveis em instruções e manuais do próprio navegador.
                </Card.Text>
                <Card.Text>
                    Para utilizar os serviços da 11PONTO11 não é necessário que o USUÁRIO permita a recepção de “cookies” eventualmente utilizados pela 11PONTO11.
                </Card.Text>
                <Card.Text>
                    A 11PONTO11 possui vários Parceiros de negócios e muitas vezes conduzimos nossos clientes, diretamente para os Sites destes parceiros. Assim, pedimos ler a Política de Privacidade de cada um deles, pois não nos responsabilizamos pela navegação no ambiente destes. Apesar de, serem empresas de grande porte e especializadas em cada serviço ou produto que disponibilizam.
                </Card.Text>
                <Card.Text>
                    Central de Atendimento 0800 780 1111 (ligação gratuita) ou através do e-mail: contato@11ponto11.com.br. As alterações sobre nossa política de privacidade serão devidamente informadas neste espaço.
                </Card.Text>
                <Card.Text>
                    Endereço para Correspondência:
                </Card.Text>
                <Card.Text>
                    11PONTO11 SOLUÇÕES E SERVIÇOS EIRELI
                </Card.Text>
                <Card.Text>
                    CENTRAL DE RELACIONAMENTO
                    Rua General Jardim, 482 – Cjto 53 – Vila Buarque – São Paulo – SP
                    CEP: 01223-010
                </Card.Text>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Fechar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalPolices;
ModalPolices.propTypes = {
    handleClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
};