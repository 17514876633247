import { REACT_APP_RECAPTCHA_KEY } from '../../config/flavor';
const generateScript = () => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${REACT_APP_RECAPTCHA_KEY}`;
    document.body.appendChild(script);
};
const generateToken = async () => {
    if (!window.grecaptcha) {
        generateScript();
        return generateToken();
    }
    return await getRecaptchaToken();
};
const getRecaptchaToken = async () => {
    return new Promise((resolve, reject) => {
        window.grecaptcha.ready(() => {
            window.grecaptcha
                .execute(REACT_APP_RECAPTCHA_KEY, { action: 'submit' })
                .then(token => {
                    resolve(token);
                })
                .catch(error => {
                    reject(error);
                });
        });
    });
};
export { generateToken, generateScript };