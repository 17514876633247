import { endPoints, namesCaches } from '../constants';
import { recoverSingleCache, storageSingleCache } from './cacheHandless';

const getMyIP = async () => {
    try {
        const cacheadIP = recoverSingleCache(namesCaches.IP);
        if (cacheadIP) {
            return cacheadIP;
        }
        const response = await fetch(endPoints.myIP);
        if (!response.ok) {
            return null;
        }
        const json = await response.json();
        const { ip } = json;
        storageSingleCache(namesCaches.IP, String(ip));
        return ip;
    } catch (error) {
        console.log(error);
        return null;
    }
};
export { getMyIP };