
/*eslint no-control-regex: "error"*/
/**
 * Formats a given string as a CPF number (Brazilian individual taxpayer registry identification).
 * The CPF format is ###.###.###-##.
 *
 * @param {Object} param - The input parameter object.
 * @param {string} param.value - The string value to be formatted.
 * @returns {string} - The formatted CPF string.
 *
 * @example
 * const formattedCPF = maskCPF({ value: '12345678901' });
 * console.log(formattedCPF); // Output: '123.456.789-01'
 */
const maskCPF = ({ value }) => {
    let v = value;
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{3})(\d)/, '$1.$2');
    v = v.replace(/(\d{3})(\d)/, '$1.$2');
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    if (v.length > 14) {
        return maskCPF(v);
    }

    return v;
};

/**
 * Formats a given string as a CNPJ number (Brazilian National Registry of Legal Entities).
 * The CNPJ format is ##.###.###/####-##.
 * If the input length is less than 15, it attempts to format it as a CPF.
 *
 * @param {Object} param - The input parameter object.
 * @param {string} param.value - The string value to be formatted.
 * @returns {string} - The formatted CNPJ or CPF string.
 *
 * @example
 * const formattedCNPJ = maskCNPJ({ value: '12345678000199' });
 * console.log(formattedCNPJ); // Output: '12.345.678/0001-99'
 */
const maskCNPJ = ({ value }) => {
    if (!value) {
        return '';
    }

    let v = value;
    v = v.replace(/\D/g, '');
    v = v.replace(/^(\d{2})(\d)/, '$1.$2');
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    v = v.replace(/\.(\d{3})(\d)/, '.$1/$2');
    v = v.replace(/(\d{4})(\d)/, '$1-$2');
    if (v.length > 18) {
        return maskCNPJ({ value: v.substring(0, 18) });
    }

    if (v.length < 15) {
        return maskCPF({ value: v });
    }

    return v;
};

/**
 * Formats a given string as a CEP number (Brazilian postal code).
 * The CEP format is #####-###.
 *
 * @param {Object} param - The input parameter object.
 * @param {string} param.value - The string value to be formatted.
 * @returns {string} - The formatted CEP string.
 *
 * @example
 * const formattedCEP = maskCEP({ value: '12345678' });
 * console.log(formattedCEP); // Output: '12345-678'
 */
const maskCEP = ({ value }) => {
    if (!value) {
        return '';
    }

    return value.replace(/^(\d{5})(\d)/, '$1-$2');
};

/**
 * Formats a phone number to Brazilian format (XX) XXXX-XXXX or (XX) XXXXX-XXXX.
 *
 * @param {string} phoneNumber - The phone number to format.
 * @returns {string} The formatted phone number.
 */
function maskPhone({ value }) {
    if (!value) {
        return '';
    }

    // Remove all non-numeric characters from the input string
    const digitsOnly = value.replace(/\D/g, '');

    // Check the length to determine the format
    const formattedNumber = digitsOnly.length === 11
        ? digitsOnly.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
        : digitsOnly.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');

    return formattedNumber;
}

/**
 * Validates a Brazilian CPF or CNPJ number.
 * The function checks if the input is a valid CPF or CNPJ number.
 *
 * @param {string} value - The CPF or CNPJ number to be validated.
 * @returns {boolean} - Returns true if the CPF or CNPJ is valid, otherwise false.
 *
 * @example
 * const isValidCPF = validateCPFOrCNPJ('123.456.789-09');
 * console.log(isValidCPF); // Output: false
 *
 * const isValidCNPJ = validateCPFOrCNPJ('12.345.678/0001-95');
 * console.log(isValidCNPJ); // Output: true
 */
function validateCPFOrCNPJ({ value }) {
    const cleanValue = value.replace(/\D/g, '');

    if (cleanValue.length === 11) {
        return validateCPF(cleanValue);
    }

    if (cleanValue.length === 14) {
        return validateCNPJ(cleanValue);
    }

    return false;
}

/**
 * Validates a Brazilian CPF number.
 *
 * @param {string} cpf - The CPF number to be validated.
 * @returns {boolean} - Returns true if the CPF is valid, otherwise false.
 */
function validateCPF(cpf) {
    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
        return false;
    }

    let sum = 0;
    for (let i = 0; i < 9; i++) {
        sum += parseInt(cpf.charAt(i), 10) * (10 - i);
    }

    let remainder = 11 - (sum % 11);
    if (remainder === 10 || remainder === 11) {
        remainder = 0;
    }

    if (remainder !== parseInt(cpf.charAt(9), 10)) {
        return false;
    }

    sum = 0;
    for (let i = 0; i < 10; i++) {
        sum += parseInt(cpf.charAt(i), 10) * (11 - i);
    }

    remainder = 11 - (sum % 11);
    if (remainder === 10 || remainder === 11) {
        remainder = 0;
    }

    if (remainder !== parseInt(cpf.charAt(10), 10)) {
        return false;
    }

    return true;
}

/**
 * Validates a Brazilian CNPJ number.
 *
 * @param {string} cnpj - The CNPJ number to be validated.
 * @returns {boolean} - Returns true if the CNPJ is valid, otherwise false.
 */
function validateCNPJ(cnpj) {
    if (cnpj.length !== 14 || /^(\d)\1+$/.test(cnpj)) {
        return false;
    }

    let length = cnpj.length - 2;
    let numbers = cnpj.substring(0, length);
    const digits = cnpj.substring(length);
    let sum = 0;
    let pos = length - 7;

    for (let i = length; i >= 1; i--) {
        sum += numbers.charAt(length - i) * pos--;
        if (pos < 2) {
            pos = 9;
        }
    }

    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(0), 10)) {
        return false;
    }

    length++;
    numbers = cnpj.substring(0, length);
    sum = 0;
    pos = length - 7;

    for (let i = length; i >= 1; i--) {
        sum += numbers.charAt(length - i) * pos--;
        if (pos < 2) {
            pos = 9;
        }
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(1), 10)) {
        return false;
    }

    return true;
}

/**
 * Removes dashes, dots, accents, and other non-numeric characters from a string.
 * @param {string} str - The string to be processed.
 * @returns {string} Returns a new string containing only the numbers.
 * @example
 *const text = '123-456.789'
 *const cleanedText = cleanString(text)
 *console.log(cleanedText) // Output: "123456789"
 */
function cleanString(str) {
    // Remove all non-numeric characters using a regular expression
    return str.replace(/[^\d]/g, '');
}

/**
 * Remove dots, commas, and dashes from a string.
 *
 * @param {string} value - The string from which dots, commas, and dashes will be removed.
 * @returns {string} A new string with dots, commas, and dashes removed.
 * @throws {null} If the argument is not a string.
 * @example
 * const stringWithSpecialChars = '1.234,567-890';
 * const stringWithoutSpecialChars = removeSpecialChars(stringWithSpecialChars);
 * console.log(stringWithoutSpecialChars); // Output: "1234567890"
 */
const removeSpecialChars = value => {
    if (typeof value !== 'string') {
        return null;
    }

    return value.replace(/[.,-]/g, '');
};
/**
 * Converts a string representing a numeric value to a formatted currency string in Brazilian Real (BRL).
 *
 * @function
 * @param {string} stringValue - The string representing the numeric value.
 * @param {boolean} show_tipe_coin - The string show type coin, for exemplo R$ when formatted currency string in Brazilian.
 * @returns {string} - The formatted currency string in Brazilian Real (BRL).
 * @throws {string} - return string empty when error.
 */
const formatCurrency = (stringValue, show_tipe_coin = true) => {
    /**
     * Numeric value obtained by parsing the input string.
     * @type {number}
     */
    const numberValue = parseFloat(stringValue);

    if (isNaN(numberValue)) {
        return '';
    }

    /**
     * Formatted currency string in Brazilian Real (BRL).
     * @type {string}
     */
    const formattedString = numberValue.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });
    if (!show_tipe_coin) {
        return formattedString.replace('R$', '').trim();
    }
    return formattedString;
};
/**
 * Converts a string to title case.
 * @param {string} value - The string to convert.
 * @returns {string} The string converted to title case.
 */
const convertToTitleCase = (value) => {
    if (!value) {
        return '';
    }
    return value.toLowerCase().replace(/\b\w/g, function (char) {
        return char.toUpperCase();
    });
};
/**
 * Checks if the target string contains the specified keyword.
 *
 * @param {string} targetString The string to search for the keyword.
 * @param {string} keyword The keyword to search for in the target string.
 * @returns {boolean} True if the keyword is found in the target string, false otherwise.
 */
function checkForJsonKeyword(targetString, keyword) {
    // Convert both strings to lowercase for a case-insensitive search
    targetString = targetString.toLowerCase();
    keyword = keyword.toLowerCase();

    // Check if the keyword exists in the target string
    return targetString.includes(keyword);
}
/**
 * Removes all spaces and diacritics (accents) from a string.
 *
 * @param {string} input - The string from which spaces and diacritics will be removed.
 * @returns {string} - The cleaned string without spaces and diacritics.
 */
function removeSpacesAndDiacritics(input) {
    // Normalize the string to decompose combined characters into base characters and diacritics
    const normalizedString = input.normalize('NFD');

    // Remove diacritics using a regular expression
    const stringWithoutDiacritics = normalizedString.replace(/[\u0300-\u036f]/g, '');

    // Remove all spaces
    const cleanedString = stringWithoutDiacritics.replace(/\s+/g, '');

    return cleanedString;
}
/**
 * Extracts the "Message" from a JSON string.
 *
 * @param {string} jsonString - The JSON string from which to extract the message.
 * @returns {string|null} - The extracted message, or null if the message is not found or if parsing fails.
 */
function extractMessageFromJson(jsonString) {
    try {
        // Parse the JSON string into an object
        const jsonObject = JSON.parse(jsonString);

        // Check if the "Message" property exists and return it
        if (jsonObject.Message) {
            return jsonObject.Message;
        } else {
            return null;
        }
    } catch (error) {
        // Log the error for debugging purposes
        console.error('Failed to parse JSON:', error);
        return null;
    }
}
/**
 * Splits a string into two variables based on a specified separator.
 *
 * @param {string} fullString - The complete string to be split.
 * @param {string} separator - The character used to separate the string into parts.
 * @returns {Object} An object containing two variables, each representing a part of the split string.
 *
 * @example
 * const fullString = 'pedido_venda.ped_venda_cod';
 * const result = splitString(fullString, '.');
 * console.log(result);
 * // Output:
 * // {
 * //   firstPart: 'pedido_venda',
 * //   secondPart: 'ped_venda_cod',
 * // }
 */
function splitString(fullString, separator = '.') {
    try {
        // Split the string using the specified separator
        const parts = fullString.split(separator);

        // Assign each part to variables
        const firstPart = parts?.[0] || '';
        const secondPart = parts?.[1] || '';

        // Return an object containing the two parts
        return {
            firstPart,
            secondPart,
        };
    } catch (error) {
        console.log(error);
        return {
            firstPart: '',
            secondPart: ''
        };
    }
}
/**
 * Validates if a given string is a valid email address.
 * The email should follow the format: localpart@domain
 * Example: user@example.com
 *
 * @param {string} email - The email address to validate.
 * @returns {boolean} - Returns true if the email address is valid, false otherwise.
 */
function isValidEmail(email) {
    // Basic regular expression to validate email address format
    const emailRegex = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
    return emailRegex.test(email);
}

/**
* Validates if a given string is a valid Brazilian phone number.
* The phone number should follow the formats: (XX) XXXXX-XXXX or (XX) XXXX-XXXX
* Example: (11) 91234-5678 or (11) 1234-5678
*
* @param {string} phoneNumber - The phone number to validate.
* @returns {boolean} - Returns true if the phone number is valid, false otherwise.
*/
function isValidBrazilianPhoneNumber(phoneNumber) {
    // Regular expression to validate Brazilian phone number format
    const phoneRegex = /^\(?\d{2}\)?\s?\d{4,5}-\d{4}$/;
    return phoneRegex.test(phoneNumber);
}
/**
 * Replaces multiple words in a string with a specified value.
 * @param {string} text - The input text.
 * @param {string[]} wordsToRemove - An array containing the words to be removed.
 * @param {string} [replacement=" "] - The value to replace the words with (default is a space).
 * @returns {string} The input text with the specified words replaced by the replacement value.
 * @example
 * // Example usage:
 * const inputText = "This is an example text with multiple words that need to be removed.";
 * const wordsToRemove = ["example", "words", "removed"];
 * const newText = replaceWords(inputText, wordsToRemove);
 * console.log(newText);
 */
function replaceWords(text, wordsToRemove, replacement = '') {
    try {
        const regex = new RegExp(wordsToRemove.join('|'), 'gi');
        return text.replace(regex, replacement);
    } catch (error) {
        console.log(error);
        return text;
    }
}
/**
 * Cleans a formatted phone string, removing all non-numeric characters.
 * Example: (11) 91234-5678 -> 11912345678
 *
 * @param {string} phoneString - The formatted phone string to clean.
 * @returns {string} - The cleaned phone string containing only numbers.
 */
function cleanPhoneString(phoneString) {
    return phoneString.replace(/\D/g, '');
}
export {
    maskCNPJ,
    maskCPF,
    maskCEP,
    maskPhone,
    validateCPFOrCNPJ,
    cleanString,
    removeSpecialChars,
    formatCurrency,
    convertToTitleCase,
    checkForJsonKeyword,
    removeSpacesAndDiacritics,
    extractMessageFromJson,
    splitString,
    isValidEmail,
    isValidBrazilianPhoneNumber,
    replaceWords,
    cleanPhoneString
};
