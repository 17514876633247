import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import PeopleIdentification from '../../views/people/identification';
import { pathViews } from '../../constants';
import PeoplePerfil from '../../views/people/perfil';
import PeopleHealthInsurance from '../../views/people/convenio';
import NotFound from '../../views/notfound';
import Home from '../../views/home';
import Proposal from '../../views/people/proposal';
import Navbar from '../../components/navbar';
import ButtonWhatsapp from '../../components/button/whatsapp';
import NotRegistred from '../../views/people/notregistred';
import ProposalSuccess from '../../views/people/proposal/success';
import NoLimit from '../../views/noLimits';

const AppRouter = () => {
    const location = useLocation();

    return (
        <>
            <Navbar />
            <ToastContainer />
            <Routes>
                <Route index element={<PeopleIdentification />} />
                <Route path={pathViews.IDENTIFICATION} element={<PeopleIdentification />} />
                <Route path={pathViews.HOME} element={<Home />} />
                <Route path={pathViews.PROFILE} element={<PeoplePerfil />} />
                <Route path={pathViews.CONVENIOS} element={<PeopleHealthInsurance />} />
                <Route path={pathViews.PROPOSAL} element={<Proposal />} />
                <Route path={pathViews.PROPOSAL_SUCCESS} element={<ProposalSuccess />} />
                <Route path={pathViews.NOT_FOUND} element={<NotFound />} />
                <Route path={pathViews.NOT_REGISTRED} element={<NotRegistred />} />
                <Route path={pathViews.NO_LIMITS} element={<NoLimit />} />
            </Routes>
            {location.pathname !== pathViews.HOME || location.pathname !== pathViews.NOT_REGISTRED ? (
                <ButtonWhatsapp />
            ) : ''}
        </>
    );
};
export default AppRouter;
