import {
    API_WHATSAPP_URL
} from '../../config/flavor';
import { ENV } from '../../config';
import Package from '../../../package.json';
export const getBaseUrl = () => {
    switch (ENV) {
    case 'develop': {
        return formatURL(API_WHATSAPP_URL);
    }

    case 'test': {
        return formatURL(API_WHATSAPP_URL);
    }

    case 'homol': {
        return formatURL(API_WHATSAPP_URL);
    }

    case 'production': {
        return formatURL(API_WHATSAPP_URL);
    }

    default:
        return formatURL('');
    }
};


const formatURL = rawUrl => `${rawUrl}`;
export const getVersion = () => {
    const { version } = Package;
    return String(version).slice(0, 3);
};

export const headerOptions = (uri, method, body) => {
    const token = 'a0875e61576ea32b3cd77a724e46e442';
    let headers = {
        'Content-Type': 'application/json',
    };
    if (token) {
        headers = {
            'Content-Type': 'application/json',
            Token: `${token}`,
        };
    }

    if (String(method).toLocaleLowerCase() === 'get' && ENV === 'develop') {
        console.log({
            url: `${getBaseUrl()}${uri}`,
            options: {
                method,
                headers,
            },
        });
        return {
            url: `${getBaseUrl()}${uri}`,
            options: {
                method,
                headers,
            },
        };
    }

    console.log({
        url: `${getBaseUrl()}${uri}`,
        options: {
            method,
            headers,
            body: JSON.stringify(body),
        },
    });
    return {
        url: `${getBaseUrl()}${uri}`,
        options: {
            method,
            headers,
            body: JSON.stringify(body),
        },
    };
};
