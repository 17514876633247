import React from 'react';
import { Card, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { pathViews } from '../../constants';
import { FaHome } from '../../assets/11ponto11/icons';
const NotFound = () => {
    return (
        <Container fluid>
            <Container className='d-flex justify-content-center align-items-center vh-100 vw-100'>
                <Card>
                    <Card.Body>
                        <Card.Text>Não encontramos a página solicitada</Card.Text>
                        <Link className='text-decoration-none text-capitalize text-secondary' to={pathViews.INDEX}>Voltar para tela inicial <FaHome /></Link>
                    </Card.Body>
                </Card>
            </Container>
        </Container>
    );
};
export default NotFound;