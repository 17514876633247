import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faLock, faAddressCard, faArrowRight, faCircleQuestion, faCircleCheck, faCircleXmark,
    faHome, faDollarSign, faPhone, faEnvelope, faPaperPlane, faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as Whatsapp } from './whatsap.svg';

const FaLock = () => (
    <FontAwesomeIcon icon={faLock} />
);
const FaAddressCard = () => (
    <FontAwesomeIcon icon={faAddressCard} />
);
const FaArrowRight = () => (
    <FontAwesomeIcon icon={faArrowRight} />
);
const FaWhatsapp = (props) => (
    <Whatsapp {...props} />
);
const FaQuestion = () => (
    <FontAwesomeIcon icon={faCircleQuestion} />
);
const FaCircleCheck = () => (
    <FontAwesomeIcon icon={faCircleCheck} className='bg-sucess' />
);
const FaCircleXmark = () => (
    <FontAwesomeIcon icon={faCircleXmark} className='bg-danger rounded-4' />
);
const FaHome = () => (
    <FontAwesomeIcon icon={faHome} />
);
const FaDollarSign = () => (
    <FontAwesomeIcon icon={faDollarSign} size='lg' />
);
const FaPhone = () => (
    <FontAwesomeIcon icon={faPhone} />
);
const FaEnvelop = () => (
    <FontAwesomeIcon icon={faEnvelope} />
);
const FaPaperPlane = () => (
    <FontAwesomeIcon icon={faPaperPlane} />
);
const FaInfoCircle = () => (
    <FontAwesomeIcon icon={faInfoCircle} />
);
export {
    FaLock,
    FaAddressCard,
    FaArrowRight,
    FaWhatsapp,
    FaQuestion,
    FaCircleCheck,
    FaCircleXmark,
    FaHome,
    FaDollarSign,
    FaPhone,
    FaEnvelop,
    FaPaperPlane,
    FaInfoCircle
};
