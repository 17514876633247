
const storageCache = (nameCache, value, sessionStorage) => {
    if (!value) {
        if (sessionStorage) {
            window.sessionStorage.removeItem(nameCache);
        }
        return window.localStorage.removeItem(nameCache);
    }
    if (sessionStorage) {
        return window.sessionStorage.setItem(nameCache, JSON.stringify(value));
    }
    return window.localStorage.setItem(nameCache, JSON.stringify(value));
};

const recoverCache = (nameCache, sessionStorage = false) => {
    if (sessionStorage) {
        return JSON.parse(window.sessionStorage.getItem(nameCache));
    }
    return JSON.parse(window.localStorage.getItem(nameCache));
};

const postCache = (nameCache, value) => {
    if (!value) {
        if (sessionStorage) {
            return window.sessionStorage.removeItem(nameCache);
        }
        return window.localStorage.removeItem(nameCache);
    }

    const oldeData = JSON.parse(window.localStorage.getItem(nameCache));
    if (!Array.isArray(oldeData)) {
        return window.localStorage.setItem(
            nameCache,
            JSON.stringify([
                {
                    ...value,
                },
            ]),
        );
    }

    const newArray = [...oldeData, { ...value }];
    return window.localStorage.setItem(
        nameCache,
        JSON.stringify([...newArray]),
    );
};

const storageSingleCache = (nameCache, value, sessionStorage) => {
    if (!value) {
        if (sessionStorage) {
            return window.sessionStorage.removeItem(nameCache);
        }
        return window.localStorage.removeItem(nameCache);
    }
    if(sessionStorage) {
        return window.sessionStorage.setItem(nameCache, String(value));
    }
    return window.localStorage.setItem(nameCache, String(value));
};

const recoverSingleCache = nameCache => window.localStorage.getItem(nameCache);
/**
 * Clears all entries from the local storage.
 *
 * This function removes all key-value pairs from the local storage, effectively resetting it.
 *
 * @returns {void}
 */
const clearAllLocalStorage = () => {
    window.localStorage.clear();
};

export {
    storageCache,
    postCache,
    storageSingleCache,
    recoverCache,
    recoverSingleCache,
    clearAllLocalStorage
};
