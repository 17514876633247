import React from 'react';
import { Card } from 'react-bootstrap';
import Phases from '../../../../components/form/Phases';
const Body = () => {
    return (
        <Card.Body className='mt-5 mb-3'>
            <Phases />
            <h6>Vamos começar:</h6>
            <Card.Title>
                Por qual tipo de Convênio você procura hoje?
            </Card.Title>
            <Card.Text>Selecione uma das opções abaixo</Card.Text>
        </Card.Body>
    );
};

export default Body;
