import React, { useContext } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import ItemLoan from './item';
import { Col, Form } from 'react-bootstrap';
import { formContext } from '../../../context/formcontext';
import PropTypes from 'prop-types';
import { convertToTitleCase } from '../../../helpers/stringHandless';

const ListLoan = ({ items }) => {
    const { handleForm, state } = useContext(formContext);
    const { idConvenio, isNotRegistred } = state;
    const onClick = item => {
        const { id_convenio: tempIdConvenio } = item;
        if (tempIdConvenio === idConvenio) {
            return handleForm({
                idConvenio: null,
            });
        }

        handleForm({
            idConvenio: tempIdConvenio,
            ...item,
        });
    };
    const onChange = ({ target: { value: item } }) => {
        try {
            const { id_convenio: tempIdConvenio } = JSON.parse(item);
            if (tempIdConvenio === idConvenio) {
                return handleForm({
                    idConvenio: null,
                });
            }

            handleForm({
                idConvenio: tempIdConvenio,
                ...item,
            });
        } catch (error) {
            console.log(error);
        }
    };
    if (!Array.isArray(items)) {
        return (
            <Col>Não há itens para serem exibidos</Col>
        );
    }
    if (isNotRegistred) {
        return (
            <Form.Select size="lg" className='mb-3' onChange={onChange}>
                <option>Selecionar</option>
                {items.map(item => (
                    <option key={item.id_convenio} value={JSON.stringify(item)}>
                        {convertToTitleCase(item?.nome_convenio)}
                    </option>
                ))}
            </Form.Select>
        );
    }
    return (
        <ListGroup>
            {items.map(item => (
                <ItemLoan key={item.id_convenio} item={item} onClick={() => onClick(item)} />
            ))}
        </ListGroup>
    );
};

export default ListLoan;
ListLoan.propTypes = {
    items: PropTypes.array.isRequired,
};
