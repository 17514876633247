import React, { createContext, useEffect, useReducer } from 'react';
import {
    maskCEP, maskCNPJ, maskCPF, maskPhone,
} from '../../helpers/stringHandless';
import PropTypes from 'prop-types';
import { recoverCache, storageCache } from '../../helpers/cacheHandless';
import { namesCaches } from '../../constants';

export const formContext = createContext();
const initialState = {
    cpf: '',
    phases: 1,
    idConvenio: null,
    matricula: '',
    vlMultiploSaque: '',
    limiteUtilizado: '',
    limiteTotal: '',
    limiteDisponivel: '',
    vlLimiteParcela: '',
    limiteParcelaUtilizado: '',
    limiteParcelaDisponivel: '',
    vlMargem: '',
    vlMultiploCompra: '',
    vlLimiteCompra: '',
    cdBanco: '',
    cdAgencia: '',
    cdConta: '',
    naoPerturbe: '',
    saqueComplementar: '',
    contratoRefinanciamento: '',
    refinanciamento: '',
    numeroContratos: '',
    vlMaximoParcela: '',
    valor: '',
    opcoes: [],
    qtdParcelas: '',
    vlLimite: '',
    cpf_cliente: '',
    id_convenio: '',
    parcela: '',
    tipoSaque: '',
    nsu: '',
    dataBase: '',
    numeroParcelas: '',
    valorFinanciado: '',
    valorParcela: '',
    valorPrincipal: '',
    valorBruto: '',
    valorLiquido: '',
    valorIOC: '',
    taxaCliente: '',
    taxaAP: '',
    taxaCET: '',
    taxaNominal: '',
    data1Vcto: '',
    dataUltVcto: '',
    taxaCETMes: '',
    taxaClienteAno: '',
    faixa: '',
    valorPremio: '',
    valorPremioBruto: '',
    coberturas: '',
    alterarDadosBancarios: false,
    telefone: '',
    celular: '',
    email: '',
    idAverbacao: '',
    tipoConta: 1,
    banco: '',
    agencia: '',
    conta: '',
    digito: '',
    aceitaPctVantagens: true,
    designarBeneficiarioCredcesta: false,
    cpfBeneficiarioCredcesta: '',
    nomeBeneficiarioCredcesta: '',
    parentescoBeneficiarioCredcesta: '',
    dataNascimentoBeneficiarioCredcesta: '',
    idCanalVenda: 2,
    cpfDigitador: '',
    salarioLiquido: 0,
    preVenda: true,
    faixaPctVantagens: '1',
    aceitavantagens: false,
    isNotRegistred: false,
    nome_convenio: '',
    linkFormalizacao: ''
};
const reducer = (state, { field, value }) => ({
    ...state,
    [field]: value,
});
const FormContext = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { id_convenio } = state;
    useEffect(() => {
        const controlState = () => {
            handleForm({
                idConvenio: id_convenio
            });
        };
        controlState();
    }, [id_convenio]);
    useEffect(() => {
        const controllCache = () => {
            const recoverStateCache = recoverCache(namesCaches.STATE);
            if (recoverStateCache) {
                handleForm({
                    ...recoverStateCache
                });
            }
        };
        controllCache();
    }, []);
    const setCacheState = ({ name, value }) => {
        const recoverStateCache = recoverCache(namesCaches.STATE);
        if (recoverStateCache) {
            storageCache(namesCaches.STATE, {
                ...recoverStateCache,
                [name]: value
            });
        } else {
            storageCache(namesCaches.STATE, {
                [name]: value
            });
        }
    };
    const onChange = ({ target }) => {
        const { name, value } = target;
        setCacheState({ name, value });
        dispatch({ field: name, value });
    };

    const onChangeNumber = ({ target }) => {
        const { name, value } = target;
        dispatch({ field: name, value: Number(value.replace('^[0-9]*$', '')) });
    };

    const onBlurValidMinMax = ({ target }) => {
        const { name, min, max, value } = target;
        if (Number(value) < Number(min)) {
            dispatch({ field: name, value: '' });
        }

        if (Number(value) > Number(max)) {
            dispatch({ field: name, value: '' });
        }
    };

    const handleForm = field => {
        Object.keys(field).forEach(key => {
            setCacheState({ name: key, value: field[key] });
            dispatch({ field: key, value: field[key] });
        });
    };

    const onChangeSelectInput = ({ target }) => {
        const value = JSON.parse(target.value);
        handleForm(value);
    };

    const onChangePhone = ({ target }) => {
        const { value, name } = target;
        setCacheState({ name, value });
        const r = maskPhone({ value: value });
        return dispatch({ field: name, value: r });
    };

    const onChangeCEP = ({ target }) => {
        const { value, name } = target;
        return dispatch({
            field: name,
            value: maskCEP(value),
        });
    };

    const onChangeCPF = ({ target }) => {
        const { value, name } = target;
        setCacheState({ name, value });
        return dispatch({
            field: name,
            value: maskCPF(value),
        });
    };

    const onChangeCNPJ = ({ target }) => {
        const { value, name } = target;
        setCacheState({ name, value });
        return dispatch({
            field: name,
            value: maskCNPJ({ value }),
        });
    };

    return <formContext.Provider
        value={{
            state,
            dispatch,
            onChange,
            onChangeNumber,
            onBlurValidMinMax,
            handleForm,
            onChangeSelectInput,
            onChangePhone,
            onChangeCEP,
            onChangeCPF,
            onChangeCNPJ,
        }}>
        {children}
    </formContext.Provider>;
};

export default FormContext;
FormContext.propTypes = {
    children: PropTypes.node.isRequired,
};
