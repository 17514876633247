import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { formatCurrency } from '../../../../helpers/stringHandless';
import { formContext } from '../../../../context/formcontext';
import { isValid } from '../../../../helpers/arrayHandless';
import NotProposal from '../notProposal';
const NSU = () => {
    const { state } = useContext(formContext);
    const [valid, setValid] = useState(false);
    const {
        nsu,
        numeroParcelas,
        valorFinanciado,
        valorParcela,
        valorPrincipal,
        valorBruto,
        valorLiquido,
        valorIOC,
        taxaCliente,
        taxaAP,
        taxaCET,
        taxaNominal,
        taxaCETMes,
        taxaClienteAno,
        cpf,
        idConvenio,
        telefone,
        celular,
        email,
        cdBanco,
        cdAgencia,
        cdConta,
        dataNascimentoBeneficiarioCredcesta,
        faixa,
    } = state;
    useEffect(() => {
        const controllValid = () => {
            const tempValid = isValid({
                numeroParcelas,
                valorFinanciado,
                valorParcela,
                valorPrincipal,
                valorBruto,
                valorLiquido,
                valorIOC,
                taxaCliente,
                taxaAP,
                taxaCET,
                taxaNominal,
                taxaCETMes,
                taxaClienteAno,
                cpf,
                idConvenio,
                telefone,
                celular,
                email,
                cdBanco,
                cdAgencia,
                cdConta,
                faixa,
            });
            console.log(tempValid);
            setValid(tempValid);
        };
        controllValid();
    }, [
        nsu,
        numeroParcelas,
        valorFinanciado,
        valorParcela,
        valorPrincipal,
        valorBruto,
        valorLiquido,
        valorIOC,
        taxaCliente,
        taxaAP,
        taxaCET,
        taxaNominal,
        taxaCETMes,
        taxaClienteAno,
        cpf,
        idConvenio,
        telefone,
        celular,
        email,
        cdBanco,
        cdAgencia,
        cdConta,
        dataNascimentoBeneficiarioCredcesta,
        faixa,
    ]);
 
    if (!valid) {
        <NotProposal />;
    }
    return (
        <Card className='mb-3'>
            <Card.Body>
                <Row>
                    <Col sm={6} md={6} className='mb-3'>
                        <span className='text-capitalize text-secondary me-2'>Valor financiado:</span>
                    </Col>
                    <Col md={12} sm={12}>
                        <h1 className='display-1'>
                            {formatCurrency(valorFinanciado)}
                        </h1>
                    </Col>
                    <hr />
                    <Col sm={6} md={4} className='mb-3'>
                        <span className='text-capitalize text-secondary me-2'>N° Parcelas:</span> {numeroParcelas}x
                    </Col>
                    <Col sm={6} md={4} className='mb-3'>
                        <span className='text-capitalize text-secondary me-2'>Valor da Parcela:</span> {formatCurrency(valorParcela)}
                    </Col>
                    <Col sm={6} md={4} className='mb-3'>
                        <span className='text-capitalize text-secondary me-2'>Valor principal:</span> {formatCurrency(valorPrincipal)}
                    </Col>
                    <Col sm={6} md={4} className='mb-3'>
                        <span className='text-capitalize text-secondary me-2'>Valor Bruto:</span> {formatCurrency(valorBruto)}
                    </Col>
                    <Col sm={6} md={4} className='mb-3'>
                        <span className='text-capitalize text-secondary me-2'>Valor Líquido:</span> {formatCurrency(valorLiquido)}
                    </Col>
                    <Col sm={6} md={4} className='mb-3'>
                        <span className='text-capitalize text-secondary me-2'>Valor IOC:</span> {formatCurrency(valorIOC)}
                    </Col>
                    <Col sm={6} md={4} className='mb-3'>
                        <span className='text-capitalize text-secondary me-2'>Taxa do cliente:</span> {formatCurrency(taxaCliente)}
                    </Col>
                    <Col sm={6} md={4} className='mb-3'>
                        <span className='text-capitalize text-secondary me-2'>Taxa AP:</span> {formatCurrency(taxaAP)}
                    </Col>
                    <Col sm={6} md={4} className='mb-3'>
                        <span className='text-capitalize text-secondary me-2'>Taxa CET:</span> {formatCurrency(taxaCET)}
                    </Col>
                    <Col sm={6} md={4} className='mb-3'>
                        <span className='text-capitalize text-secondary me-2'>Taxa Nominal:</span> {formatCurrency(taxaNominal)}
                    </Col>
                    <Col sm={6} md={4} className='mb-3'>
                        <span className='text-capitalize text-secondary me-2'>Taxa CET ao Mes:</span> {formatCurrency(taxaCETMes)}
                    </Col>
                    <Col sm={12} md={4} className='mb-3'>
                        <span className='text-capitalize text-secondary me-2'>Taxa Cliente ao Ano:</span> {formatCurrency(taxaClienteAno)}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};
export default NSU;