import React from 'react';
import { Card } from 'react-bootstrap';
import Phases from '../../../../components/form/Phases';
const Body = () => {
    return (
        <Card.Body className='mb-3'>
            <Phases />
            <h6>Para finalizarmos</h6>
            <Card.Title>
                Essa é sua proposta
            </Card.Title>
        </Card.Body>
    );
};

export default Body;
