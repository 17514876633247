import React, { useContext, useEffect, useState } from 'react';
import {
    Card, Col, Container, Row,
} from 'react-bootstrap';
import ListHealthInsurance from '../../../components/list/healthInsurance';
import Body from './body';
import { formContext } from '../../../context/formcontext';
import { dismissAll, handleWarn } from '../../../components/toastfy';
import { Person } from '../../../assets/11ponto11/person-magnifying-glass';
import { flipImage } from './style';
import { requestContext } from '../../../context/requestContext';
import { useNavigate } from 'react-router-dom';
import { pathViews } from '../../../constants';

const PeopleHealthInsurance = () => {
    const { handleForm } = useContext(formContext);
    const { responseConsultaConvenio } = useContext(requestContext);
    const [items, setItems] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        dismissAll();
    }, []);
    useEffect(() => {
        handleForm({
            phases: 3,
            idConvenio: null,
        });
    }, []);
    useEffect(() => {
        const controlSetresponseConsultaConvenio = () => {
            if (Array.isArray(responseConsultaConvenio)) {
                setItems(responseConsultaConvenio);
            }
        };
        controlSetresponseConsultaConvenio();
    }, [responseConsultaConvenio]);
    useEffect(() => {
        const verifyItems = () => {
            if (items?.length) {
                const filterBylimiteDisponivel = items.filter(item => item?.limiteDisponivel > 0);
                if (!filterBylimiteDisponivel?.length) {
                    handleWarn({ message: 'Não há limite para saque' });
                    return navigate(pathViews.NO_LIMITS); 
                }
            }
        };
        verifyItems();
    }, [items]);
    return (
        <Container fluid className='mb-5'>
            <div className='d-block d-md-none'>
                <Card className='border-0 rounded'>
                    <Body />
                    <Card.Body>
                        <ListHealthInsurance items={items} />
                    </Card.Body>
                </Card>
            </div>
            <div className='d-none d-md-flex'>
                <Row className='align-items-end'>
                    <Col md={6}>
                        <Card>
                            <Card.Body>
                                <Body />
                                <ListHealthInsurance items={items} />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Person style={flipImage} />
                    </Col>
                </Row>
            </div>
        </Container >
    );
};

export default PeopleHealthInsurance;
