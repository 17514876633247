import React from 'react';
import './styles.css';
import { Card, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
const HowDo = ({ items }) => {
    if (!Array.isArray(items)) {
        return <Col className='col-12'></Col>;
    }
    return (
        <Col className="col-12">
            <Col className="col-12">
                {items.sort((a, b) => a.id - b.id).map(item => (
                    <Card className='border border-greek mb-3 bg-dark' key={item.id}>
                        <Row className='d-flex flex-nowrap align-items-center p-3'>
                            <Col sm={6} md={10}>
                                <Card.Text className='text-light text-center'>
                                    {item.title}
                                </Card.Text>
                            </Col>
                            <Col sm={6} md={2} className='rounded-circle circle d-none d-md-flex'>
                                <Card.Text className='text-light fw-bold'>
                                    {String(item.id).padStart(2, '0')}
                                </Card.Text>
                            </Col>
                        </Row>
                        <Card.Text className='p-3 text-light'>
                            {item.text}
                        </Card.Text>
                    </Card>
                ))}
            </Col>
        </Col>
    );
};
export default HowDo;
HowDo.propTypes = {
    items: PropTypes.array.isRequired,
};
