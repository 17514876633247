import React, { useContext } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { convertToTitleCase } from '../../helpers/stringHandless';
import { formContext } from '../../context/formcontext';
import { FaHome, FaWhatsapp } from '../../assets/11ponto11/icons';
import { Link } from 'react-router-dom';
import { CONTACT, NAME_CONTACT } from '../../config/flavor';
import { pathViews } from '../../constants';
const NoLimit = () => {
    const { state } = useContext(formContext);
    const { nome } = state;
    const handleWhatsapp = () => {
        const message = `Olá ${NAME_CONTACT} tudo bem? \n Poderia me ajudar?`;
        window.open(`https://api.whatsapp.com/send?phone=55${CONTACT}&text=${message}`, 'blank').focus();
    };
    return (
        <Container className='mt-5'>
            <Row>
                <Col>
                    <Card.Img src={require('../../assets/11ponto11/nao_tem_limite.png')} alt="Card image" />
                </Col>
                <Col>
                    <Card className="border-0">
                        <Card.Body className="bg-light">
                            {nome ? (
                                <Card.Title>Caro(a) {convertToTitleCase(nome)}</Card.Title>
                            ) : ''}
                            <Card.Text>Infelizmente Não há limite para saque, você pode tentar novamente com outro CPF ou nos chamar no Whatsapp <FaWhatsapp /></Card.Text>
                            <Row>
                                <Col>
                                    <Card.Text>
                                        <Link to={''} className='text-decoration-none text-dark'
                                            onClick={handleWhatsapp}><span className='text-secondary'>
                                                Clique aqui
                                            </span> para nos chamar no whatsap</Link>
                                    </Card.Text>
                                </Col>
                                <Col>
                                    <Card.Text>
                                        <Link to={pathViews.INDEX} className='text-decoration-none text-dark'><span className='text-secondary'>
                                            Clique aqui
                                        </span> para voltar para tela inicial <FaHome /></Link>
                                    </Card.Text>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};
export default NoLimit;