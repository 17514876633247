import React from 'react';
import { Card, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaWhatsapp } from '../../../assets/11ponto11/icons';
import { CONTACT, NAME_CONTACT } from '../../../config/flavor';
const NotRegistred = () => {
    const handleWhatsapp = () => {
        const message = `Olá ${NAME_CONTACT} tudo bem? \n Poderia me ajudar?`;
        window.open(`https://api.whatsapp.com/send?phone=55${CONTACT}&text=${message}`, 'blank').focus();
    };
    return (
        <Container fluid>
            <Container className='d-flex justify-content-center align-items-center vh-100 vw-100'>
                <Card>
                    <Card.Body>
                        <Card.Text>
                            Você não tem cadastro em nosso sistema, você pode tentar novamente com outro CPF ou Solicitar seu cadastro pelo Whatsapp <FaWhatsapp />
                        </Card.Text>
                        <Card.Text>
                            <Link to={''} className='text-decoration-none text-dark'
                                onClick={handleWhatsapp}><span className='text-secondary'>
                                    Clique aqui
                                </span> para nos chamar no whatsap</Link>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Container>
        </Container>
    );
};
export default NotRegistred;