import {toast} from 'react-toastify';

function handleDefault({message, options}) {
    if (!message) {
        return;
    }

    if (options) {
        return toast(message, options);
    }

    return toast(message);
}

function handleError({message, options}) {
    if (!message) {
        return;
    }

    if (options) {
        return toast.error(message, options);
    }

    return toast.error(message);
}

function handleSuccess({message, options}) {
    if (!message) {
        return;
    }

    if (options) {
        return toast.success(message, options);
    }

    return toast.success(message);
}

function handleInfo({message, options}) {
    if (!message) {
        return;
    }

    if (options) {
        return toast.info(message, options);
    }

    return toast.info(message);
}

function handleWarn({message, options}) {
    if (!message) {
        return;
    }

    if (options) {
        return toast.warn(message, options);
    }

    return toast.warn(message);
}

function handleLoading({message, options}) {
    if (!message) {
        return;
    }

    if (options) {
        return toast.loading(message, options);
    }

    return toast.loading(message);
}

function handleUpdate({id, message, options}) {
    if (!message || !id) {
        return toast.update(id, {
            render: 'Dados carregados com sucesso', type: 'success', isLoading: false, ...options,
        });
    }

    if (options) {
        return toast.update(id, {
            render: message, type: 'success', isLoading: false, ...options,
        });
    }

    return toast.update(id, {render: message, type: 'success', isLoading: false});
}

const dismissAll = () => toast.dismiss();

export {
    handleDefault,
    handleError,
    handleSuccess,
    handleInfo,
    handleWarn,
    handleLoading,
    handleUpdate,
    dismissAll,
};
