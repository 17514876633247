import React from 'react';
import { findIconBank } from '../../../helpers/arrayHandless';
import PropTypes from 'prop-types';

const IconBank = ({ codigo_bank }) => {
    const srcIcon = findIconBank(codigo_bank);
    if (!srcIcon) {
        return (
            <></>
        );
    }
    return (
        <img src={srcIcon}
            className="rounded-2"
            style={{
                maxWidth: 35,
                maxHeight: 35
            }} />
    );
};
export default IconBank;
IconBank.propTypes = {
    codigo_bank: PropTypes.number.isRequired
};