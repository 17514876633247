/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */
import React, { createContext, useEffect, useState } from 'react';
export const requestContext = createContext();
import PropTypes from 'prop-types';
import { dismissAll, handleError, handleLoading, handleUpdate } from '../../components/toastfy';
import { configMsgWhatsapp, endPoints, messagesToUser, namesCaches } from '../../constants';
import { headerOptions } from '../../api';
import { headerOptions as headerOptionsWhatsapp } from '../../api/whatsapp';
import { cleanPhoneString, extractMessageFromJson, removeSpacesAndDiacritics, removeSpecialChars } from '../../helpers/stringHandless';
import { find } from '../../helpers/arrayHandless';
import { recoverCache, storageCache } from '../../helpers/cacheHandless';
import { encrypyData } from '../../helpers/cryptojsHandless';

const RequestContext = ({ children }) => {
    const [responseConsultaCPF, setResposeConsultaCPF] = useState(null);
    const [responseConsultaConvenio, setResposeConsultaConvenio] = useState(null);
    const [responseConsultaSaque, setResponseConsultaSaque] = useState(null);
    const [responseSucessoEnvioProposta, setSucessoEnvioProposta] = useState(null);
    const responseWhenErrorApi = ({ toasId }) => handleUpdate({ id: toasId, message: messagesToUser.messageErrorRequestDoc });
    useEffect(() => {
        const controllCache = () => {
            const recoverresponseConsultaCPF = recoverCache(namesCaches.RESPONSECONSULTACPF);
            if (recoverresponseConsultaCPF) {
                setResposeConsultaCPF(recoverresponseConsultaCPF);
            }

            const recoverresponseConsultaConvenio = recoverCache(namesCaches.RESPONSECONSULTACONVENIO);
            if (recoverresponseConsultaConvenio) {
                setResposeConsultaConvenio(recoverresponseConsultaConvenio);
            }

            const recoverresponseConsultaSaque = recoverCache(namesCaches.RESPONSECONSULTASAQUE);
            if (recoverresponseConsultaSaque) {
                setResponseConsultaSaque(recoverresponseConsultaSaque);
            }

            const recoverresponseSucessoEnvioProposta = recoverCache(namesCaches.RESPONSESUCESSOENVIOPROPOSTA);
            if (recoverresponseSucessoEnvioProposta) {
                setSucessoEnvioProposta(recoverresponseSucessoEnvioProposta);
            }
        };
        controllCache();
    }, []);
    useEffect(() => {
        const controllCache = () => {
            if (responseConsultaCPF) {
                storageCache(namesCaches.RESPONSECONSULTACPF, responseConsultaCPF);
            }
            if (responseConsultaConvenio) {
                storageCache(namesCaches.RESPONSECONSULTACONVENIO, responseConsultaConvenio);
            }
            if (responseConsultaSaque) {
                storageCache(namesCaches.RESPONSECONSULTASAQUE, responseConsultaSaque);
            }
            if (responseSucessoEnvioProposta) {
                storageCache(namesCaches.RESPONSESUCESSOENVIOPROPOSTA, responseSucessoEnvioProposta);
            }
        };
        controllCache();
    }, [
        responseConsultaCPF,
        responseConsultaConvenio,
        responseConsultaSaque,
        responseSucessoEnvioProposta,
    ]);
    const fetchConvenio = async ({
        toasId
    }) => {
        try {
            const { url, options } = await headerOptions(endPoints.requestConvenios, 'POST');
            const response = await fetch(url, options);

            if (!response.ok) {
                responseWhenErrorApi({
                    toasId
                });
            } else {
                const json = await response.json();
                if (json.length) {
                    setResposeConsultaCPF([...json, {
                        isNotRegistred: true
                    }]);
                } else {
                    setResponseConsultaSaque(json);
                }
            }
            setTimeout(() => {
                dismissAll();
            }, 4000);
            return response.ok;
        } catch (error) {
            console.log(error);
            return false;
        }
    };
    const fetchConsultaCPF = async ({
        cpf,
        celular,
        email
    }) => {
        try {
            setResposeConsultaCPF(null);
            const toasId = handleLoading({
                message: messagesToUser.messageLoadingRequestDoc,
            });
            const payload = await encrypyData({
                cpf_cliente: removeSpecialChars(cpf),
                email,
                celular
            });
            const { options, url } = await headerOptions(endPoints.requestCpf, 'POST', payload);
            const response = await fetch(url, options);

            if (!response.ok) {
                responseWhenErrorApi({ toasId });
            }
            const text = await response.text();
            const json = JSON.parse(text);
            if (String(json)?.toLocaleLowerCase() === messagesToUser.messageDocNotFound.toLocaleLowerCase()) {
                return fetchConvenio({ toasId });
            }
            if (json?.mensagem?.toLocaleLowerCase() === messagesToUser.messageDocNotFoundInDb.toLocaleLowerCase()) {
                return fetchConvenio({ toasId });
            }
            setResposeConsultaCPF(json);
            setTimeout(() => {
                dismissAll();
            }, 4000);
            if (response.ok) {
                await fetchEnviaMsgWhatsapp({ celular });
            }
            return response.ok;
        } catch (error) {
            console.log(error);
            dismissAll();
            handleError({ message: messagesToUser.messageErrorRequestDoc });
            return false;
        }
    };
    const fetchConsultaLimite = async ({
        cpf = null,
        id_convenio = 0
    }) => {
        try {
            const toasId = handleLoading({
                message: messagesToUser.messageLoadingRequestLimit,
            });
            const payload = await encrypyData({
                cpf_cliente: removeSpecialChars(cpf),
                id_convenio: parseInt(id_convenio)
            });
            const { options, url } = await headerOptions(endPoints.requestLimit, 'POST', payload);
            const response = await fetch(url, options);

            if (!response.ok) {
                responseWhenErrorApi({ toasId });
            }
            const json = await response.json();
            if (json?.mensagem) {
                const { mensagem } = json;
                handleUpdate({ id: toasId, message: mensagem });
            }
            setTimeout(() => {
                dismissAll();
            }, 4000);
            setResposeConsultaConvenio(json);
            return response.ok;
        } catch (error) {
            console.log(error);
            dismissAll();
            handleError({ message: messagesToUser.messageErrorRequestDoc });
            return {
                data: [],
                ok: false
            };
        }
    };
    const tryDecodeString = (cleanedStr) => {
        try {
            return decodeURIComponent(JSON.parse(`"${cleanedStr.replace(/\"/g, '\\"')}"`));
        } catch (error) {
            console.log(error);
            return null;
        }
    };
    const tryDecodeJSON = ({
        response,
        cleanedStr,
        toasId,
        decodedStr,
        specialHooks
    }) => {
        try {
            const json = JSON.parse(cleanedStr || decodedStr);
            if (json?.mensagem) {
                const { mensagem } = json;
                handleUpdate({ id: toasId, message: mensagem });
            }
            if (json?.Message) {
                const errorMessage = extractMessageFromJson(json);
                handleError({ message: errorMessage });
            }
            if (json?.message) {
                const { mensagem } = json;
                handleError({ message: mensagem });
            }
            setTimeout(() => {
                dismissAll();
            }, 4000);
            if (specialHooks) {
                specialHooks(json);
            } else {
                setResponseConsultaSaque(json);
            }
            return response.ok;
        } catch (parseError) {
            console.error('Erro ao analisar JSON:', parseError);
            dismissAll();
            handleError({
                id: toasId,
                message: messagesToUser.messageErroDafault,
            });
            return false;
        }
    };
    const fetchConsultarSaque = async ({
        cpf_cliente,
        id_convenio,
        matricula,
        valor,
        parcela,
        tipoSaque,
    }) => {
        try {
            const toasId = handleLoading({
                message: messagesToUser.messageLoadingRequestLimit,
            });

            const payload = await encrypyData({
                cpf_cliente: removeSpecialChars(cpf_cliente),
                id_convenio: String(id_convenio),
                matricula,
                valor: Number(valor),
                parcela: Number(parcela),
                tipoSaque: removeSpacesAndDiacritics(tipoSaque),
            });

            const { options, url } = await headerOptions(endPoints.requestSaque, 'POST', payload);
            const response = await fetch(url, options);


            if (!response.ok) {
                responseWhenErrorApi({ toasId });
                return;
            }

            const text = await response.text();
            const cleanedStr = text.replace(/^"+|"+$/g, ''); // Remove aspas duplas

            // Decodifica caracteres Unicode
            const decodedStr = tryDecodeString(cleanedStr);
            const possibleMessages = [
                { message: messagesToUser.messageErroTipoSaque, },
                { message: messagesToUser.messageErroContract, },
                { message: messagesToUser.messageErrorRequestSaqueNotLimit, },
            ];
            const { data: findMessage } = find(possibleMessages, 'message', decodedStr);
            if (findMessage) {
                handleError({
                    id: toasId, message: decodedStr
                });
                setTimeout(() => {
                    dismissAll();
                }, 6000);
                return false;
            }
            return tryDecodeJSON({
                cleanedStr,
                decodedStr,
                response,
                toasId
            });
        } catch (error) {
            console.log(error);
            dismissAll();
            handleError({ message: messagesToUser.messageErroDafault });
            return {
                data: [],
                ok: false,
            };
        }
    };
    const fetchEnviarProposta = async ({
        cpf_cliente,
        nsu,
        id_convenio,
        celular,
        email,
        tipoConta,
        numbanco,
        numAgencia,
        numConta,
        digito,
        aceitavantagens,
        designarBenificiario,
        cpfBenificiario,
        nomeBenificiario,
        parenteNome,
        nascimentoData,
        prevenda,
        faixaPctVantagens,
    }) => {
        try {
            const payload = await encrypyData({
                cpf_cliente: removeSpecialChars(cpf_cliente),
                nsu,
                id_convenio,
                alterarDadosBancarios: false,
                celular: cleanPhoneString(celular),
                email,
                tipoConta: String(tipoConta),
                numbanco,
                numAgencia,
                numConta,
                digito,
                aceitavantagens,
                designarBenificiario,
                cpfBenificiario,
                nomeBenificiario,
                parenteNome,
                nascimentoData,
                salario: 5987.65,
                prevenda,
                faixaPctVantagens: faixaPctVantagens ? String(faixaPctVantagens) : faixaPctVantagens,
            });
            const toasId = handleLoading({
                message: messagesToUser.messageLoadingRequestEnviaProposta
            });
            const { url, options } = await headerOptions(endPoints.requestEnviaProposta, 'POST', { ...payload });
            const response = await fetch(url, options);

            if (!response.ok) {
                dismissAll();
                return handleError({ message: messagesToUser.messageErroDafault });
            }
            const text = await response.text();
            const cleanedStr = text.replace(/^"+|"+$/g, ''); // Remove aspas duplas
            const decodedStr = tryDecodeString(cleanedStr);
            return tryDecodeJSON({
                response,
                cleanedStr,
                decodedStr,
                toasId,
                specialHooks: setSucessoEnvioProposta
            });
        } catch (error) {
            return false;
        }
    };
    const fetchEnviaMsgWhatsapp = async ({
        celular,
        nome = false
    }) => {
        try {
            let payload = {
                destino: cleanPhoneString(celular),
                ...configMsgWhatsapp.CONSULT,
            };
            if (nome) {
                const { SUCCESS } = configMsgWhatsapp;
                const { idFila, idModelo } = SUCCESS;
                payload = {
                    destino: cleanPhoneString(celular),
                    idFila,
                    idModelo,
                    variaveis: [nome]
                };
            }
            const { url, options } = headerOptionsWhatsapp(endPoints.sendMsgWhatsapp, 'POST', payload);
            const response = await fetch(url, options);

            if (response.ok) {
                const json = await response.json();
                return storageCache(namesCaches.SEND_MSG_WHATSAPP, json);
            }
            storageCache(namesCaches.SEND_MSG_WHATSAPP);
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <requestContext.Provider
            value={{
                responseConsultaCPF,
                responseConsultaConvenio,
                responseConsultaSaque,
                responseSucessoEnvioProposta,
                setResposeConsultaCPF,
                setResposeConsultaConvenio,
                setResponseConsultaSaque,
                fetchConsultaCPF,
                fetchConsultaLimite,
                fetchConsultarSaque,
                fetchEnviarProposta,
                fetchEnviaMsgWhatsapp
            }}
        >
            {children}
        </requestContext.Provider>
    );
};

export default RequestContext;
RequestContext.propTypes = {
    children: PropTypes.node.isRequired,
};
