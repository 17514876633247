import Package from '../../../package.json';
import { API_URL_LOCAL } from '../local-config';
const FLAVOR = '11ponto11';
const API_URL_TEST = 'http://189.1.163.98:8000';
const API_URL_HOMOL = 'https://11ponto11vendas.com.br';
const API_URL_PRODUCTION = 'https://onzeponto11.com.br';
const API_URL_DEVELOP = API_URL_LOCAL;
const VERSION = Package.version;
const URL_FILE_DEVELOP = `${API_URL_LOCAL}/storage`;
const URL_FILE_TEST = `${API_URL_TEST}/storage`;
const NAME_CONTACT = FLAVOR;
const LINK_ENDERECO_MAPS = '';
const ENDERECO = '';
const CONTACT = '08006071111';
const FACEBOOK = '';
const INSTAGRAM = '';
const backgroundColor = 'rgb(0,39,83)';
const API_WHATSAPP_URL = 'https://11ponto11.cloudnexcore.com.br';
const REACT_APP_RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;
const ENCRYPTS = {
    isEnable:  true,
    allPayload: true
};
const SEND_GA_ID = true;
export {
    FLAVOR,
    API_URL_DEVELOP,
    API_URL_TEST,
    API_URL_HOMOL,
    API_URL_PRODUCTION,
    VERSION,
    URL_FILE_DEVELOP,
    URL_FILE_TEST,
    NAME_CONTACT,
    LINK_ENDERECO_MAPS,
    ENDERECO,
    CONTACT,
    FACEBOOK,
    INSTAGRAM,
    backgroundColor,
    API_WHATSAPP_URL,
    REACT_APP_RECAPTCHA_KEY,
    ENCRYPTS,
    SEND_GA_ID
};
