import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import RequestContext from './context/requestContext';
import FormContext from './context/formcontext';
import { BrowserRouter } from 'react-router-dom';
import Router from './router';
import ReactGA from 'react-ga';
import { storageSingleCache } from './helpers/cacheHandless';
import { namesCaches } from './constants';
import { SEND_GA_ID } from './config/flavor';
const App = () => {
    useEffect(() => {
        if (!process.env.REACT_APP_GT || !SEND_GA_ID) {
            return;
        }
        ReactGA.initialize(process.env.REACT_APP_GT);
        ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.ga((tracker) => {
            storageSingleCache(namesCaches.GA_ID, tracker.get('clientId'));
        });
        console.log('done');
    }, []);
    return (
        <BrowserRouter>
            <RequestContext>
                <FormContext>
                    <Router />
                </FormContext>
            </RequestContext>
        </BrowserRouter>
    );
};
export default App;
