import React, { useEffect } from 'react';
import AppRouter from './appRouter';
import { FLAVOR } from '../config/flavor';

const Router = () => {
    useEffect(() => {
        const setTitleBody = () => {
            window.document.title = `${FLAVOR} em Parceria com Banco Master`;
        };

        setTitleBody();
    }, []);
    return (
        <AppRouter />
    );
};

export default Router;
