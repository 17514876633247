const styleToLargeSize = {
    width: '100%',
    height: '100%'
};
const styleToLSmallSize = {
    maxWidth: 350,
    maxHeight: 250
};
const zIndexColumns = {
    zIndex: 1,
};
const styleColorCard = { backgroundColor: 'transparent' };
export {
    styleToLargeSize,
    styleToLSmallSize,
    styleColorCard,
    zIndexColumns
};