import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { convertToTitleCase, formatCurrency } from '../../../helpers/stringHandless';
import { Col, Row } from 'react-bootstrap';
import { FaCircleXmark } from '../../../assets/11ponto11/icons';
const RenderCoberturas = ({ row }) => {
    const {
        valorCobertura,
        descricaoCobertura,
        textoCobertura,
        quantidade,
        codigoCobertura
    } = row;
    return (
        <Row>
            <Col className='mb-3' md={12} sm={12}>
                <span className='text-capitalize text-secondary'>
                    {descricaoCobertura} ({codigoCobertura}):
                </span>
            </Col>
            <Col className='mb-3' md={12} sm={12}>
                {convertToTitleCase(textoCobertura)}
            </Col>
            <Col className='mb-3' md={6} sm={6}>
                <span className='text-capitalize text-secondary me-3'>
                    Valor da cobertura:
                </span>
                {formatCurrency(valorCobertura)}
            </Col>
            <Col className='mb-3' md={6} sm={6}>
                <span className='text-capitalize text-secondary me-3'>
                    Quantidade:
                </span>
                {quantidade}
            </Col>
            <hr />
        </Row>
    );
};
const ModalParameters = ({ show, handleClose, coberturas }) => {
    return (
        <Modal show={show} onHide={handleClose} size='lg'>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <Modal.Title>Quais são as vantagens desse convenio?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {coberturas?.length ? coberturas.map((item, i) => (
                        <RenderCoberturas row={item} key={i} />
                    )) : ''}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose} variant="secondary">Fechar <FaCircleXmark /></Button>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

export default ModalParameters;
ModalParameters.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    coberturas: PropTypes.array,
};
RenderCoberturas.propTypes = {
    row: PropTypes.object,
};