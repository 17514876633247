import React, { useContext } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import ItemHealthInsurance from './item';
import { Button, Card, Col } from 'react-bootstrap';
import { formContext } from '../../../context/formcontext';
import { requestContext } from '../../../context/requestContext';
import { FaArrowRight } from '../../../assets/11ponto11/icons';
import PropTypes from 'prop-types';
import { handleWarn } from '../../toastfy';
import { useNavigate } from 'react-router-dom';
import { opcoesSaque, pathViews } from '../../../constants';
import { removeSpacesAndDiacritics } from '../../../helpers/stringHandless';

const ListHealthInsurance = ({ items }) => {
    const { handleForm, state } = useContext(formContext);
    const { fetchConsultarSaque } = useContext(requestContext);
    const {
        idConvenio,
        cpf,
        phases,
        cpf_cliente,
        matricula,
        valor,
        parcela,
        tipoSaque } = state;
    const navigate = useNavigate();
    const onClick = item => {
        const { idConvenio: id_convenio, cpf: cpf_cliente, matricula, opcoes, limiteDisponivel: valor, saqueComplementar } = item;
        const { qtdParcelas: parcela } = opcoes[0];
        const item_selected = {
            cpf_cliente,
            matricula,
            valor: Number(Math.abs(valor)) - 1.60,
            parcela,
            id_convenio,
            tipoSaque: saqueComplementar ? opcoesSaque.SAQUE_COMPLEMENTAR : opcoesSaque.SAQUE_FACIL
        };
        handleForm({
            ...item_selected,
            ...item
        });
    };
    const onSubmit = async () => {
        window.scrollTo(0, 0);
        if (idConvenio === null || !cpf) {
            return handleWarn({ message: 'Você precisa selecionar pelo menus um convenvio' });
        }
        const response = await fetchConsultarSaque({
            id_convenio: idConvenio,
            cpf_cliente,
            matricula,
            valor,
            parcela,
            tipoSaque: removeSpacesAndDiacritics(String(tipoSaque))

        });
        if (response) {
            handleForm({
                phases: phases + 1
            });
            navigate(pathViews.PROPOSAL);
        }
    };
    if (!Array.isArray(items)) {
        return (
            <Col>Não há itens para serem exibidos</Col>
        );
    }

    return (
        <Card className='border-0 rounded'>
            <ListGroup>
                {items.map(item => (
                    <ItemHealthInsurance key={item.idConvenio} item={item} onClick={() => onClick(item)} />
                ))}
            </ListGroup>
            <div className='d-grid gap-2'>
                <Button className='d-none d-md-block' variant='outline-dark' disabled={idConvenio === null} size='lg' onClick={onSubmit}>
                    Continuar {' '} <FaArrowRight />
                </Button>
                <Button className='d-block d-md-none fixed-bottom rounded-0' variant='dark' disabled={idConvenio === null} size='lg' onClick={onSubmit}>
                    Continuar {' '} <FaArrowRight />
                </Button>
            </div>
        </Card>
    );
};

export default ListHealthInsurance;
ListHealthInsurance.propTypes = {
    items: PropTypes.array.isRequired,
};
