import { FLAVOR } from '../config/flavor';

const messagesToUser = {
    cpfOrCnpjInvalid: type => `O ${type === 0 ? 'CPF' : 'CNPJ'} informado não é valido.`,
    contractTerms: [
        { id: 0, text: '1. Autoriza a 11Ponto11, parceiro do Banco Master a e consultar meus dados pessoais no Sistema de Informações de Crédito (SCR), do Banco Central do Brasil (BACEN), conforme disposto na Resolução CMN n° 5.037 de 29 de setembro de 2022.' },
        { id: 1, text: '2. Aceita os Termos de Uso Política de Privacidade da aplicação', hiperlink: '/' },
        { id: 2, text: `3. Aceita receber contato do ${FLAVOR} sobre produtos financeiro  via  Whatsapp  ou outro meio de contato.` },
    ],
    messageInfoHomePage: 'Somos Correspondentes Bancários das Principais Instituições Financeiras do País, inclusos o Banco Master.,Somos regidos pela Resolução CMN: 4935 de 29/07/2021 e remunerados pelos Bancos Contratantes. De acordo com o Art.11 – I ,dessa resolução, nos é vedado : I - a cobrança de tarifas, comissão, valores referentes a ressarcimento de serviços prestados por terceiros ou qualquer outra forma de remuneração, pelo fornecimento de produtos ou serviços destas Instituições',
    messageLoadingRequestDoc: 'Aguarde, estamos consultando seu CPF',
    messageLoadingRequestLimit: 'Aguarde, estamos preparando as melhores propostas para você!',
    messageLoadingRequestSaque: 'Aguarde, estamos preparando sua proposta',
    messageLoadingRequestEnviaProposta: 'Aguarde, estamos enviando sua proposta',
    messageErrorRequestSaqueNotLimit: 'Cliente não possui limite suficiente para esta operação.',
    messageErrorRequestDoc: 'Não foi possível consultar seu CPF',
    messageDocNotFound: 'Cliente não cadastrado',
    messageDocNotFoundInDb: 'Cliente não cadastrado na base de dados',
    messageErroTipoSaque: 'Operação de Saque Complementar não permitida. Cliente não possui margem averbada/ativa com o convênio',
    messageErroDafault: 'Não foi possível completar sua solicitação. Tente novamente mais tarde.',
    messageErroContract: 'Contrato com Atraso e/ou Inadimplencia',
    messageNotBenefits: 'Pacote de vantagem encontrado e NÃO pode ser ofertado!',
};
const pathViews = {
    INDEX: '/',
    HOME: '/home',
    IDENTIFICATION: '/identification',
    NOT_REGISTRED: 'not_registred',
    PROFILE: '/profile',
    CONVENIOS: '/convenios',
    PROPOSAL: '/proposal',
    PROPOSAL_SUCCESS: '/proposal_success',
    NOT_FOUND: '*',
    NO_LIMITS: '/no_limits'
};
const namesCaches = {
    ACCES_TOKEN: 'access_token',
    SEND_MSG_WHATSAPP: 'send_msg_whatsapp',
    PEM: 'pem',
    VERSION: 'version',
    IP: 'ip',
    RESPONSECONSULTACPF: 'responseconsultacpf',
    RESPONSECONSULTACONVENIO: 'responseconsultaconvenio',
    RESPONSECONSULTASAQUE: 'responseconsultasaque',
    RESPONSESUCESSOENVIOPROPOSTA: 'responsesucessoenvioproposta',
    STATE: 'state',
    GA_ID: 'ga_id'
};
const endPoints = {
    requestCpf: '/consulta/cpf/master/',
    requestLimit: '/consultar/limite/master/',
    requestConvenios: '/consultar/convenios/master/',
    requestSaque: '/simular/saque/master/',
    requestEnviaProposta: '/efetivar/proposta/master/',
    sendMsgWhatsapp: '/ncall/api/v1/chat/enviaModeloMensagem',
    requestPem: '/get-key/',
    myIP: 'http://ip.jsontest.com/'
};
const opcoesSaque = {
    SAQUE_FACIL: 'Saque Fácil',
    SAQUE_COMPLEMENTAR: 'Saque Complementar'
};
const justUniqDoc = false;
const bankArray = [
    {
        'codigo_bank': 1,
        'name_bank': 'Banco do Brasil S.A'
    },
    {
        'codigo_bank': 3,
        'name_bank': 'Banco da Amazônia S.A'
    },
    {
        'codigo_bank': 4,
        'name_bank': 'Banco do Nordeste do Brasil S.A'
    },
    {
        'codigo_bank': 7,
        'name_bank': 'Banco Nacional de Desenvolvimento Econômico e Social'
    },
    {
        'codigo_bank': 12,
        'name_bank': 'Banco Inbursa S.A'
    },
    {
        'codigo_bank': 15,
        'name_bank': 'Ubs Brasil Cctvm S.A'
    },
    {
        'codigo_bank': 25,
        'name_bank': 'Banco Alfa S.A'
    },
    {
        'codigo_bank': 29,
        'name_bank': 'Banco Itaú Consignado S.A'
    },
    {
        'codigo_bank': 33,
        'name_bank': 'Banco SANTANDER'
    },
    {
        'codigo_bank': 36,
        'name_bank': 'Banco Bbi S.A'
    },
    {
        'codigo_bank': 37,
        'name_bank': 'Banco do Estado do Pará S.A'
    },
    {
        'codigo_bank': 40,
        'name_bank': 'Banco Cargill S.A'
    },
    {
        'codigo_bank': 41,
        'name_bank': 'Banco do Estado do Rio Grande do Sul S.A'
    },
    {
        'codigo_bank': 47,
        'name_bank': 'Banco do Estado de Sergipe S.A'
    },
    {
        'codigo_bank': 51,
        'name_bank': 'Banco de Desenvolvimento do Espírito Santo S.A'
    },
    {
        'codigo_bank': 62,
        'name_bank': 'Hipercard Bm S.A'
    },
    {
        'codigo_bank': 63,
        'name_bank': 'Banco Bradescard S.A'
    },
    {
        'codigo_bank': 64,
        'name_bank': 'Goldman Sachs Do Brasil Bm S.A'
    },
    {
        'codigo_bank': 65,
        'name_bank': 'Banco Andbank (Brasil) S.A'
    },
    {
        'codigo_bank': 66,
        'name_bank': 'Banco Morgan Stanley S.A'
    },
    {
        'codigo_bank': 69,
        'name_bank': 'Banco Crefisa S.A'
    },
    {
        'codigo_bank': 70,
        'name_bank': 'BANCO DE BRASÍLIA (BRB)'
    },
    {
        'codigo_bank': 74,
        'name_bank': 'Banco. J.Safra S.A'
    },
    {
        'codigo_bank': 75,
        'name_bank': 'Banco ABN AMRO S.A'
    },
    {
        'codigo_bank': 76,
        'name_bank': 'Banco KDB S.A'
    },
    {
        'codigo_bank': 77,
        'name_bank': 'Banco Inter'
    },
    {
        'codigo_bank': 77,
        'name_bank': 'Banco Inter S.A'
    },
    {
        'codigo_bank': 79,
        'name_bank': 'Banco Original Do Agronegócio S.A'
    },
    {
        'codigo_bank': 80,
        'name_bank': 'B&T Cc Ltda'
    },
    {
        'codigo_bank': 82,
        'name_bank': 'Banco Topázio S.A'
    },
    {
        'codigo_bank': 83,
        'name_bank': 'Banco da China Brasil S.A'
    },
    {
        'codigo_bank': 84,
        'name_bank': 'Uniprime Norte Do Paraná'
    },
    {
        'codigo_bank': 85,
        'name_bank': 'Coop Central Ailos'
    },
    {
        'codigo_bank': 91,
        'name_bank': 'Unicred Central Rs'
    },
    {
        'codigo_bank': 93,
        'name_bank': 'Pólocred Scmepp Ltda'
    },
    {
        'codigo_bank': 94,
        'name_bank': 'Banco Finaxis S.A'
    },
    {
        'codigo_bank': 96,
        'name_bank': 'Banco B3 S.A'
    },
    {
        'codigo_bank': 99,
        'name_bank': 'Uniprime Central Ccc Ltda'
    },
    {
        'codigo_bank': 100,
        'name_bank': 'Planner Corretora De Valores S.A'
    },
    {
        'codigo_bank': 102,
        'name_bank': 'Xp Investimentos S.A'
    },
    {
        'codigo_bank': 104,
        'name_bank': 'Banco Caixa Econômica Federal'
    },
    {
        'codigo_bank': 105,
        'name_bank': 'Lecca Cfi S.A'
    },
    {
        'codigo_bank': 107,
        'name_bank': 'Banco BOCOM BBM S.A'
    },
    {
        'codigo_bank': 108,
        'name_bank': 'Portocred S.A'
    },
    {
        'codigo_bank': 114,
        'name_bank': 'Central Cooperativa De Crédito No Estado Do Espírito Santo'
    },
    {
        'codigo_bank': 117,
        'name_bank': 'Advanced Cc Ltda'
    },
    {
        'codigo_bank': 121,
        'name_bank': 'Banco Agibank S.A'
    },
    {
        'codigo_bank': 136,
        'name_bank': 'UNICRED COOPERATIVA'
    },
    {
        'codigo_bank': 143,
        'name_bank': 'Treviso Cc S.A'
    },
    {
        'codigo_bank': 159,
        'name_bank': 'Casa Credito S.A'
    },
    {
        'codigo_bank': 172,
        'name_bank': 'Albatross Ccv S.A'
    },
    {
        'codigo_bank': 180,
        'name_bank': 'Cm Capital Markets Cctvm Ltda'
    },
    {
        'codigo_bank': 184,
        'name_bank': 'Banco Itaú BBA S.A'
    },
    {
        'codigo_bank': 188,
        'name_bank': 'Ativa Investimentos S.A'
    },
    {
        'codigo_bank': 189,
        'name_bank': 'Hs Financeira'
    },
    {
        'codigo_bank': 190,
        'name_bank': 'Servicoop'
    },
    {
        'codigo_bank': 191,
        'name_bank': 'Nova Futura Ctvm Ltda'
    },
    {
        'codigo_bank': 196,
        'name_bank': 'Banco Fair Corretora de Câmbio S.A'
    },
    {
        'codigo_bank': 197,
        'name_bank': 'Stone Pagamentos'
    },
    {
        'codigo_bank': 204,
        'name_bank': 'Banco Bradesco Cartões S.A'
    },
    {
        'codigo_bank': 208,
        'name_bank': 'Banco BTG Pactual S.A'
    },
    {
        'codigo_bank': 212,
        'name_bank': 'Banco Original'
    },
    {
        'codigo_bank': 213,
        'name_bank': 'Banco Arbi S.A'
    },
    {
        'codigo_bank': 217,
        'name_bank': 'Banco John Deere S.A'
    },
    {
        'codigo_bank': 218,
        'name_bank': 'Banco BS2 S.A'
    },
    {
        'codigo_bank': 222,
        'name_bank': 'Banco Credit Agricole Brasil S.A'
    },
    {
        'codigo_bank': 224,
        'name_bank': 'Banco Fibra S.A'
    },
    {
        'codigo_bank': 233,
        'name_bank': 'Banco Cifra S.A'
    },
    {
        'codigo_bank': 237,
        'name_bank': 'Banco Bradesco S.A'
    },
    {
        'codigo_bank': 237,
        'name_bank': 'Next'
    },
    {
        'codigo_bank': 241,
        'name_bank': 'Banco Clássico S.A'
    },
    {
        'codigo_bank': 243,
        'name_bank': 'Banco Máxima S.A'
    },
    {
        'codigo_bank': 246,
        'name_bank': 'Banco ABC Brasil S.A'
    },
    {
        'codigo_bank': 260,
        'name_bank': 'Nubank'
    },
    {
        'codigo_bank': 265,
        'name_bank': 'Banco Fator S.A'
    },
    {
        'codigo_bank': 266,
        'name_bank': 'Banco Cédula S.A'
    },
    {
        'codigo_bank': 280,
        'name_bank': 'Avista S.A. Crédito, Financiamento e Investimento'
    },
    {
        'codigo_bank': 280,
        'name_bank': 'Banco Willbank'
    },
    {
        'codigo_bank': 286,
        'name_bank': 'Ccr De Ouro'
    },
    {
        'codigo_bank': 290,
        'name_bank': 'PagBank'
    },
    {
        'codigo_bank': 300,
        'name_bank': 'Banco de La Nacion Argentina'
    },
    {
        'codigo_bank': 318,
        'name_bank': 'Banco BMG S.A'
    },
    {
        'codigo_bank': 320,
        'name_bank': 'Banco Ccb Brasil S.A'
    },
    {
        'codigo_bank': 323,
        'name_bank': 'Mercado Pago'
    },
    {
        'codigo_bank': 335,
        'name_bank': 'Banco Digio S.A'
    },
    {
        'codigo_bank': 336,
        'name_bank': 'Banco C6 S.A – C6 Bank'
    },
    {
        'codigo_bank': 340,
        'name_bank': 'Superdigital'
    },
    {
        'codigo_bank': 341,
        'name_bank': 'Banco Itaú'
    },
    {
        'codigo_bank': 348,
        'name_bank': 'Banco Xp S/A'
    },
    {
        'codigo_bank': 368,
        'name_bank': 'Banco Carrefour'
    },
    {
        'codigo_bank': 370,
        'name_bank': 'Banco Mizuho do Brasil S.A'
    },
    {
        'codigo_bank': 376,
        'name_bank': 'Banco J. P. Morgan S.A'
    },
    {
        'codigo_bank': 380,
        'name_bank': 'PicPay'
    },
    {
        'codigo_bank': 389,
        'name_bank': 'Banco Mercantil do Brasil S.A'
    },
    {
        'codigo_bank': 394,
        'name_bank': 'Banco Bradesco Financiamentos S.A'
    },
    {
        'codigo_bank': 412,
        'name_bank': 'Banco Capital S.A'
    },
    {
        'codigo_bank': 422,
        'name_bank': 'Banco Safra'
    },
    {
        'codigo_bank': 456,
        'name_bank': 'Banco MUFG Brasil S.A'
    },
    {
        'codigo_bank': 461,
        'name_bank': 'Asaas'
    },
    {
        'codigo_bank': 473,
        'name_bank': 'Banco Caixa Geral – Brasil S.A'
    },
    {
        'codigo_bank': 479,
        'name_bank': 'Banco ItauBank S.A'
    },
    {
        'codigo_bank': 494,
        'name_bank': 'Banco de La Republica Oriental del Uruguay'
    },
    {
        'codigo_bank': 495,
        'name_bank': 'Banco de La Provincia de Buenos Aires'
    },
    {
        'codigo_bank': 505,
        'name_bank': 'Banco Credit Suisse (Brasil) S.A'
    },
    {
        'codigo_bank': 600,
        'name_bank': 'Banco Luso Brasileiro S.A'
    },
    {
        'codigo_bank': 604,
        'name_bank': 'Banco Industrial do Brasil S.A'
    },
    {
        'codigo_bank': 612,
        'name_bank': 'Banco Guanabara S.A'
    },
    {
        'codigo_bank': 626,
        'name_bank': 'Banco Ficsa S.A'
    },
    {
        'codigo_bank': 637,
        'name_bank': 'BANCO SOFISA S.A (SOFISA DIRETO)'
    },
    {
        'codigo_bank': 653,
        'name_bank': 'Banco Indusval S.A'
    },
    {
        'codigo_bank': 654,
        'name_bank': 'Banco A.J.Renner S.A'
    },
    {
        'codigo_bank': 655,
        'name_bank': 'BANCO VOTORANTIM S.A'
    },
    {
        'codigo_bank': 707,
        'name_bank': 'Banco Daycoval S.A'
    },
    {
        'codigo_bank': 720,
        'name_bank': 'Banco Maxinvest S.A'
    },
    {
        'codigo_bank': 735,
        'name_bank': 'Neon Pagamentos'
    },
    {
        'codigo_bank': 739,
        'name_bank': 'Banco Cetelem S.A'
    },
    {
        'codigo_bank': 741,
        'name_bank': 'BANCO RIBEIRÃO PRETO'
    },
    {
        'codigo_bank': 743,
        'name_bank': 'Banco Semear S.A'
    },
    {
        'codigo_bank': 745,
        'name_bank': 'Banco Citibank S.A'
    },
    {
        'codigo_bank': 746,
        'name_bank': 'Banco Modal S.A'
    },
    {
        'codigo_bank': 747,
        'name_bank': 'Banco Rabobank Internacional Do Brasil S.A'
    },
    {
        'codigo_bank': 748,
        'name_bank': 'Banco Cooperativo Sicredi S.A'
    },
    {
        'codigo_bank': 752,
        'name_bank': 'Banco BNP Paribas Brasil S.A'
    },
    {
        'codigo_bank': 756,
        'name_bank': 'Banco Cooperativo do Brasil S.A. – BANCOOB'
    },
    {
        'codigo_bank': 757,
        'name_bank': 'Banco KEB HANA do Brasil S.A'
    }
];
const configMsgWhatsapp = {
    CONSULT: {
        idFila: 51,
        idModelo: 71,
        variaveis: []
    },
    SUCCESS: {
        idFila: 51,
        idModelo: 75,
        variaveis: []
    }
};
const namePem = 'public_key.pem';
export {
    messagesToUser,
    pathViews,
    namesCaches,
    endPoints,
    opcoesSaque,
    bankArray,
    justUniqDoc,
    configMsgWhatsapp,
    namePem
};
export const howTo = [
    {
        id: 1,
        text: 'Você será levado ao portal exclusivo de consulta on-liine do Banco Master',
        title: 'Clique em Consultar On-line'
    },
    {
        id: 2,
        text: 'Preencha os Dados solicitados na página de consulta,  e clique em Aceitar e Continuar. Lembrando que você estará seguro com nossa criptografia ponta-a-ponta',
        title: 'Preencha seus Dados'
    },
    {
        id: 3,
        text: 'Caso tenham linhas de crédito disponíves, você poderá ver qual é a melhor opção. Escolha aquela que se encaixa no seu perfil e clique em continuar',
        title: 'Escolha a melhor opção de crédito',
    },
    {
        id: 4,
        text: 'Após escolher a linha de crédito, confirme se tudo está de acordo com o que escolheu e clique em CONTRATAR. Pronto, você receberá um SMS para Scanner Facial, e o crédito cai na sua conta!',
        title: 'Contrate 100% On-line',
    }
];