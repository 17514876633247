import React from 'react';
import { FLAVOR } from '../../config/flavor';
import { Col, Row } from 'react-bootstrap';
const Logo = () => (
    <Row className='mt-3 bg-dark p-3'>
        <Col>
            <img src={require(`../../assets/${FLAVOR}/master-logo.webp`)} alt='Uma imagem com número a seguinte frase 11 ponto 11'
                className='image-responsive rounded-2'
                style={{ maxHeight: 30 }} />
        </Col>
        <Col>
            <span style={{
                fontSize: '0.5rem'
            }} className='text-decoration-none text-light'>
                Em parceria com a
            </span>
            <img src={require(`../../assets/${FLAVOR}/login-logo-normal.png.webp`)} alt='Uma imagem com número a seguinte frase 11 ponto 11'
                className='image-responsive rounded-2'
                style={{ maxHeight: 30 }} />
        </Col>
    </Row>
);
export default Logo;
