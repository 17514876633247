import React from 'react';
import { handleError } from '../../../../components/toastfy';
import { messagesToUser } from '../../../../constants';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FaPaperPlane } from '../../../../assets/11ponto11/icons';
import PropTypes from 'prop-types';
export const RenderButtonClick = ({ onClick }) => {
    const handleClick = ({
        aceitaPctVantagens
    }) => {
        if (!onClick) {
            return handleError({ message: messagesToUser.messageErroDafault });
        }
        return onClick({
            aceitaPctVantagens
        });
    };
    return (
        <>
            <Card.Text className='mb-3 display-6 fw-bold'>Enviar sua proposta: </Card.Text>
            <div className='d-grid gap-2 d-md-none'>
                <Button variant='dark' size='lg' className='rounded-0' onClick={() => handleClick({
                    aceitaPctVantagens: true
                })}>
                    Com seguro <FaPaperPlane />
                </Button>
                <Button variant='secondary' size='lg' className='rounded-0' onClick={() => handleClick({
                    aceitaPctVantagens: false,
                })}>
                    Sem seguro <FaPaperPlane />
                </Button>
            </div>
            <Row className='d-none d-md-flex flex-row'>
                <Col>
                    <Button variant='dark' size='lg' onClick={() => handleClick({
                        aceitaPctVantagens: true
                    })}>
                        Com seguro <FaPaperPlane />
                    </Button>
                </Col>
                <Col>
                    <Button variant='secondary' size='lg' onClick={() => handleClick({
                        aceitaPctVantagens: false,
                    })}>
                        Sem seguro <FaPaperPlane />
                    </Button>
                </Col>
            </Row>
        </>
    );
};
RenderButtonClick.propTypes = {
    onClick: PropTypes.func.isRequired
};