import React, { useContext } from 'react';
import {
    Card, Col, ListGroup, Row,
} from 'react-bootstrap';
import { formContext } from '../../../../context/formcontext';
import PropTypes from 'prop-types';
import { convertToTitleCase } from '../../../../helpers/stringHandless';

const ItemLoan = ({ item, onClick }) => {
    const { state } = useContext(formContext);
    const { idConvenio } = state;
    if (!item.id_convenio) {
        return <></>;
    }
    return (
        <ListGroup.Item className={Number(idConvenio) === Number(item.id_convenio) ? 'mb-3 rounded-4 border-2 bg-secondary' : 'mb-3 rounded-4 border-2'} as={'li'} style={{
            cursor: 'pointer',
        }} action onClick={() => onClick(item)}>
            <Row className='p-3 mb-3'>
                <Col sm={12} md={12}>
                    {item?.nome_convenio ? (
                        <Card.Text className={Number(idConvenio) === Number(item.id_convenio) ? 'text-left fw-bold text-light text-capitalize' : 'text-left fw-bold'}>
                            {convertToTitleCase(item?.nome_convenio)}
                        </Card.Text>
                    ) : ''}
                    {item?.descricao_adicional ? (
                        <span style={{
                            fontSize: '0.8rem',
                        }} className={idConvenio === item.id_convenio ? 'text-light text-capitalize' : 'text-capitalize text-secondary'}>
                            Uma descrição qui
                        </span>
                    ) : ''}
                </Col>
            </Row>
        </ListGroup.Item>
    );
};

export default ItemLoan;
ItemLoan.propTypes = {
    item: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
};
