/* eslint-disable no-undef */
import { bankArray } from '../../constants';
import { splitString } from '../stringHandless';

/**
 * Finds an item in the array based on specified parameters and value.
 *
 * @function
 * @param {Array<Object>} array - The array to search for the item.
 * @param {string} params - The property of the items to compare with the value.
 * @param {*} value - The value to find within the specified property.
 * @param {Object} rest - Additional properties to include in the result.
 * @returns {Object} - An object containing information about the found item.
 *   - `data`: The found item or `false` if not found.
 *   - `total`: The total number of items found (either 0 or 1).
 *   - Additional properties specified in the `rest` parameter.
 * @example
 * const result = find(myArray, 'name', 'John', { additionalProp: 'someValue' });
 * console.log(result);
 */
const find = (array, params, value, rest) => {
    if (!array || !params || !value) {
        return {
            data: false,
            total: 0,
            ...rest,
        };
    }
    const split = splitString(params, '.');
    const typeOf = typeof value;
    if (typeOf === 'string') {
        if (split?.firstPart && split.secondPart) {
            const filtered = array.find(
                el => String(el[split?.firstPart][split?.secondPart])?.toLowerCase()?.trim() === String(value)?.toLowerCase()?.trim(),
            );
            return {
                data: filtered,
                total: 1,
                ...rest,
            };
        }
        const filtered = array.find(
            el => String(el[params])?.toLowerCase()?.trim() === String(value)?.toLowerCase()?.trim(),
        );
        return {
            data: filtered,
            total: 1,
            ...rest,
        };
    }
    if (split?.firstPart && split?.secondPart) {
        const filtered = array.find(el => el[split?.firstPart][split?.secondPart] === value);
        return {
            data: filtered,
            total: 1,
            ...rest,
        };

    }
    const filtered = array.find(el => el[params] === value);
    return {
        data: filtered,
        total: 1,
        ...rest,
    };

};
const findIconBank = codigo_bank => {
    try {
        const { data: findItem } = find(bankArray, 'codigo_bank', Number(parseInt(codigo_bank)));
        if (!findItem) {
            return findItem;
        }
        const { name_bank } = findItem;
        const requireIcon = require(`../../assets/icons/${name_bank}/index.svg`);
        return requireIcon;
    } catch (error) {
        console.log(error);
        return false;
    }
};

/**
 * Checks if the given field is valid.
 * @param {Object} field - The field to be validated.
 * @returns {boolean} True if the field is valid, false otherwise.
 */
const isValid = (field) => {
    let newData = Object.keys(field).find((value) => field[value] === '');
    if (newData) {
        return false;
    }
    return true;
};
export {
    find,
    findIconBank,
    isValid
};