import React from 'react';
import { Button, Col, Nav, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaLock } from '../../assets/11ponto11/icons';
import { pathViews } from '../../constants';
import { FLAVOR } from '../../config/flavor';
const Navbar = () => {
    return (
        <Nav className="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
            <div className="container-fluid">
                <Link className="navbar-brand" to={pathViews.INDEX}>
                    <Row className='align-items-center'>
                        <Col>
                            <img src={require(`../../assets/${FLAVOR}/master-logo.webp`)} alt="Essa imagem exibe a logo do banco master, a imagem mostra a letra M bem grande dentro de um quadrado com fundo azul"
                                style={{
                                    maxWidth: 150
                                }}
                            />
                        </Col>
                        <Col>
                            <div style={{
                                fontSize: '0.8rem'
                            }}>
                                Parceria premium
                            </div>
                            <img src={require(`../../assets/${FLAVOR}/login-logo-normal.png.webp`)} alt='Uma imagem com número a seguinte frase 11 ponto 11'
                                className='image-responsive rounded-2'
                                style={{ maxHeight: 30 }} />
                        </Col>
                    </Row>
                </Link>
                <Button className="navbar-toggler d-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </Button>
                <div className="collapse navbar-collapse" id="navbarText">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    </ul>
                    <span className="navbar-text text-light">
                        Você está em um ambiente seguro <FaLock />
                    </span>
                </div>
            </div>
        </Nav>
    );
};
export default Navbar;