import React from 'react';
import { Card } from 'react-bootstrap';
import Phases from '../../../../components/form/Phases';
import { backgroundColor } from '../../../../config/flavor';
import { justUniqDoc } from '../../../../constants';
const Body = () => {
    return (
        <Card.Body className='mb-3'>
            <Phases />
            <Card.Title>
                Pra gente começar, informe seu <span style={{
                    color: backgroundColor
                }} className='fw-bold'>
                    {justUniqDoc ? 'CPF' : 'CPF, Email e Celular'}
                </span>
            </Card.Title>
        </Card.Body>
    );
};

export default Body;
