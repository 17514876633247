import React, { useEffect, useState } from 'react';
import { Container, Button, Row, Col, Card } from 'react-bootstrap';
import { FLAVOR, VERSION, backgroundColor } from '../../config/flavor';
import { styleColorCard, styleToLSmallSize, styleToLargeSize, zIndexColumns } from './styles';
import { useNavigate } from 'react-router-dom';
import { howTo, messagesToUser, namesCaches, pathViews } from '../../constants';
import Logo from '../../components/logo';
import HowDo from './howDo';
import { clearAllLocalStorage, recoverCache, storageCache } from '../../helpers/cacheHandless';
import { getMyIP } from '../../helpers/myIP';
const Home = () => {
    const navigate = useNavigate();
    const [isFlipping, setIsFlipping] = useState(false);
    useEffect(() => {
        const body = document.getElementById('root');
        body.className = 'bg-dark';
        body.style.overflow = 'hidden';
    }, []);
    useEffect(() => {
        const controlCacheVersion = () => {
            const recoverCachedVersion = recoverCache(namesCaches.VERSION);
            if (recoverCachedVersion?.version !== VERSION) {
                clearAllLocalStorage();
                storageCache(namesCaches.VERSION, {
                    version: VERSION
                });
            }
        };
        controlCacheVersion();
    }, []);
    useEffect(() => {
        const controllRemoteIP = async () => {
            await getMyIP();
        };
        controllRemoteIP();
    }, []);
    useEffect(() => {
        if (isFlipping) {
            const handleTransitionEnd = () => {
                setIsFlipping(false);
            };

            const imageElement = document.querySelector('.image');
            imageElement.addEventListener('transitionend', handleTransitionEnd);

            return () => {
                imageElement.removeEventListener('transitionend', handleTransitionEnd);
            };
        }
    }, [isFlipping]);
    const handlePage = () => {
        navigate(pathViews.IDENTIFICATION);
    };
    const onMouseOver = () => {
        try {
            setIsFlipping(true);
        } catch (error) {
            console.log(error);
        }
    };
    const ButtonPage = () => {
        return (
            <Button className="btn-lg w-100 rounded-1 text-light text-capitalize mb-3 bg-orange fw-bold" onClick={handlePage}>
                CONSULTAR LIMITE ON-LINE
            </Button>
        );
    };
    return (
        <Container style={{
            backgroundColor: backgroundColor,
        }}>
            <div className="jumbotron mt-5 p-5 text-light text-capitalize">
                <Row>
                    <Col md={8} sm={12}>
                        <h1 className="display-5 fw-bold">
                            <span className='greek'>Você Pode ter um Cartão aprovado</span> ou um <span className='greek me-2'>Limite Extra</span>
                            para Saque Imediato 100% On-Line
                        </h1>
                        <Card.Text className="lead greek fw-bold">
                            Exclusivo para: Servidor Estadual do Governo da Bahia e Rio de Janeiro!
                        </Card.Text>
                        <ul>
                            <li className='font-20 mb-3'>
                                Em parceria exclusiva com o Banco Master, Você pode ter um Cartão Benefício ou Cartão Consignado Pré Aprovado, com limite de até 3x o seu salário! E mais, esse limite fica disponível para saque imediato direto pra sua conta corrente!
                            </li>
                            <li className='font-20'>
                                Se você já tem o Cartão Benefício ou Cartão Consignado do Banco Master, Podemos te oferecer um limite extra para saque imediato, 100% on-line, na sua conta cadastrada.
                            </li>
                        </ul>
                        <hr className="my-4" />
                        <ButtonPage />
                    </Col>
                    <Col md={4} sm={12} className='align-items-center flip-container' id={'flip-container'}>
                        <div className={`flip-container ${isFlipping ? 'flip' : ''}`} onMouseOver={onMouseOver}>
                            <img src={require(`../../assets/${FLAVOR}/pai-filha-feliz.webp`)} alt='A imagem mostra um pai e sua filha ao lado sorrindo segurando um celular na mão, eles estão consultando o limite para sque online' className='d-none d-md-flex image-responsive image'
                                style={styleToLargeSize} />
                            <img src={require(`../../assets/${FLAVOR}/pai-filha-feliz.webp`)} alt='A imagem mostra um pai e sua filha ao lado sorrindo segurando um celular na mão, eles estão consultando o limite para sque online' className='d-flex d-md-none image-responsive image'
                                style={styleToLSmallSize} />
                        </div>
                    </Col>
                </Row>
            </div>
            <Card className='border-0' style={{ backgroundColor: 'transparent' }}>
                <Card.Body>
                    <Card.Title className='display-5 mb-3 text-light text-capitalize text-center'>Como funciona?</Card.Title>
                </Card.Body>
            </Card>
            <Card className='mb-3 border-0 rounded-2 p-4 background-index'>
                <Card.Body className='mt-5 mb-5'>
                    <Row className='d-flex align-items-center'>
                        <Col md={4} sm={12} style={zIndexColumns}>
                            <HowDo items={howTo.filter(item => item.id === 1 || item.id === 3)} />
                        </Col>
                        <Col md={4} sm={12} className='container-celular-circulo'>
                            <Col className='container-celular'>
                                <img
                                    src={require(`../../assets/${FLAVOR}/celular.png`)}
                                    className='w-100 h-100 image-responsive' style={zIndexColumns} />
                            </Col>
                        </Col>
                        <Col md={4} sm={12} style={zIndexColumns} className='mb-3'>
                            <HowDo items={howTo.filter(item => item.id === 2 || item.id === 4)} />
                        </Col>
                        <Col style={zIndexColumns}>
                            <ButtonPage />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card className='border-0' style={{ backgroundColor: 'transparent' }}>
                <Card.Body>
                    <Card.Title className='display-5 mb-3 text-light text-capitalize text-center'>
                        O QUE <span className='fw-bold'>Diz quem já Contratou?</span>
                    </Card.Title>
                </Card.Body>
            </Card>
            <Card className='border-0 mb-3' style={{ backgroundColor: 'transparent' }}>
                <Card.Body>
                    <Row>
                        <Col sm={12} md={6}>
                            <Card.Text className="p-index p-3 mb-3 text-light">
                                {'"'}Recebi um SMS com uma oferta de crédito, Acessei o site e fiz algumas simulações fechei a que se enquadrou mais no meu perfil.
                                <Card.Text className="mb-3" />
                                Tudo foi documentado e seguro. Segui todo o procedimento e o meu crédito caiu em 5 horas! Incrível.{'"'}
                            </Card.Text>
                        </Col>
                        <Col sm={12} md={6}>
                            <Card.Text className="p-index p-3 mb-3 text-light">
                                {'"'}Nunca tinha utilizado o crédito, mas bem na hora que estava precisando, pois queria trocar de apartamento, e estava buscando alternativas.
                                <Card.Text className="mb-3" />
                                Simulei várias formas de pagamento e fechei negócio, tudo de forma segura e documentada e melhor, sem sair de casa{'"'}
                            </Card.Text>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card className='mb-5' style={styleColorCard}>
                <Card.Body>
                    <Row>
                        <Col className='mb-3' md={12} sm={12}>
                            <Logo />
                        </Col>
                        <Col md={12} sm={12}>
                            <Card.Text className='text-light text-capitalize text-left'>
                                {messagesToUser.messageInfoHomePage}
                            </Card.Text>
                        </Col>
                        <Col md={12} sm={12}>
                            <Card.Text style={{
                                color: backgroundColor
                            }}>Versão do site {VERSION}</Card.Text>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    );
};
export default Home;