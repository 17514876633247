import React, {
    useContext, useEffect, useRef, useState,
} from 'react';
import { formContext } from '../../../context/formcontext';
import {
    Button, Card, Col, Form, InputGroup, Row,
} from 'react-bootstrap';
import { FaAddressCard, FaArrowRight, FaEnvelop, FaQuestion, FaWhatsapp } from '../../../assets/11ponto11/icons';
import { isValidBrazilianPhoneNumber, isValidEmail, maskCPF, maskPhone, validateCPFOrCNPJ } from '../../../helpers/stringHandless';
import { justUniqDoc, messagesToUser, pathViews } from '../../../constants';
import { handleWarn } from '../../toastfy';
import { Link, useNavigate } from 'react-router-dom';
import { requestContext } from '../../../context/requestContext';
import { backgroundColor, REACT_APP_RECAPTCHA_KEY } from '../../../config/flavor';
import { isValid } from '../../../helpers/arrayHandless';
import ModalPolices from '../../modal/polices';
const FormDocidentification = () => {
    const { state, onChangeCNPJ, onChangePhone, onChange, handleForm } = useContext(formContext);
    const { fetchConsultaCPF } = useContext(requestContext);
    const [colorButton, setColorButton] = useState('dark');
    const firstInput = useRef();
    const navigate = useNavigate();
    const [valid, setValid] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { cpf, phases, celular, telefone, email } = state;
    useEffect(() => {
        const controllValueTel = () => {
            if (celular) {
                return handleForm({
                    telefone: celular,
                });
            }
            handleForm({
                telefone: '',
            });
        };
        controllValueTel();
    }, [celular]);
    useEffect(() => {
        const controllValidForm = () => {
            const tempValid = isValid({
                cpf,
                celular,
                email
            });
            return setValid(tempValid);
        };
        controllValidForm();
    }, [cpf, celular, email]);
    useEffect(() => {
        if (phases > 1) {
            handleForm({
                phases: 1,
            });
        }
    }, [phases]);
    useEffect(() => {
        const controllFocusInput = () => {
            if (firstInput?.current && phases === 1) {
                return firstInput.current.focus();
            }
        };

        controllFocusInput();
    }, [phases]);
    useEffect(() => {
        const controllColorButton = () => {
            if (isValid({
                cpf, celular, email
            })) {
                setColorButton('dark');
            } else {
                setColorButton('secondary');
            }
        };

        controllColorButton();
    }, [cpf, celular, email]);
    const fecthPayload = async () => {
        const response = await fetchConsultaCPF({
            cpf,
            celular,
            email
        });
        if (response) {
            handleForm({
                phases: phases + 1
            });
            navigate(pathViews.PROFILE);
        }

    };
    const onSubmit = async () => {
        window.scrollTo(0, 0);
        // Agora você pode enviar o token junto com os dados do formulário para o servidor
        if (!cpf || !celular || !telefone) {
            return handleWarn({ message: 'Você precisa preencher todos os campos para poder Continuar!' });
        }
        if (cpf?.length >= 14 && phases === 1) {
            if (!validateCPFOrCNPJ({ value: cpf })) {
                return handleWarn({
                    message: `${messagesToUser.cpfOrCnpjInvalid(cpf?.length <= 14 ? 0 : 1)}`,
                });
            }
            if (!window.grecaptcha || !REACT_APP_RECAPTCHA_KEY) {
                return fecthPayload();
            }
            fecthPayload();
        } else {
            return handleWarn({ message: 'Você precisa preencher todos os campos para poder Continuar!' });
        }
    };

    const onBlur = ({ target: { value, name } }) => {
        if (!value) {
            return;
        }
        if (!validateCPFOrCNPJ({ value }) && name === 'cpf') {
            return handleWarn({
                message: `${messagesToUser.cpfOrCnpjInvalid(value?.length <= 14 ? 0 : 1)}`,
            });
        }
        if (!isValidEmail(value) && name == 'email') {
            handleForm({
                email: '',
            });
            return handleWarn({
                message: 'Por favor, digite um email que seja válido.'
            });
        }
        if (!isValidBrazilianPhoneNumber(value) && name === 'celular') {
            handleForm({
                celular: '',
            });
            return handleWarn({
                message: 'Por favor, digite um número de Whatsapp que seja válido.'
            });
        }
    };

    return (
        <Form>
            <Row>
                <Col sm={12} md={12}>
                    <InputGroup className='mb-3'>
                        <Form.Control
                            className='form-control-border'
                            ref={firstInput}
                            aria-label='Digite seu CPF'
                            aria-describedby='cpf'
                            value={cpf}
                            name='cpf'
                            onChange={onChangeCNPJ}
                            tabIndex={1}
                            placeholder={maskCPF({ value: '00000000000' })}
                            required
                            maxLength={18}
                            onBlur={onBlur}
                        />
                        <Button style={{
                            backgroundColor: backgroundColor
                        }} className='text-light text-capitalize'>
                            <FaAddressCard />
                        </Button>
                    </InputGroup>
                </Col>
                {justUniqDoc ? '' : (
                    <>
                        <Col sm={12} md={6}>
                            <InputGroup className='mb-3'>
                                <Form.Control
                                    className='form-control-border'
                                    aria-label='Digite seu e-mail'
                                    aria-describedby='email'
                                    value={email}
                                    name='email'
                                    onChange={onChange}
                                    tabIndex={1}
                                    placeholder={'meuemail@email.com'}
                                    required
                                    maxLength={100}
                                    onBlur={onBlur}
                                />
                                <Button style={{
                                    backgroundColor: backgroundColor
                                }} className='text-light text-capitalize'>
                                    <FaEnvelop />
                                </Button>
                            </InputGroup>
                        </Col>
                        <Col sm={12} md={6}>
                            <InputGroup className='mb-3'>
                                <Form.Control
                                    className='form-control-border'
                                    aria-label='Digite seu Whatsapp'
                                    aria-describedby='celular'
                                    value={celular}
                                    name='celular'
                                    onChange={onChangePhone}
                                    tabIndex={2}
                                    placeholder={maskPhone({ value: '00000000000' })}
                                    required
                                    onBlur={onBlur}
                                />
                                <Button style={{
                                    backgroundColor: backgroundColor
                                }} className='outline-light'>
                                    <FaWhatsapp fill='#fff' />
                                </Button>
                            </InputGroup>
                        </Col>
                    </>
                )}
                <Col sm={12} md={12} className='mb-3'>
                    <Card bg='light border-0 mb-3'>
                        <Card.Body>
                            <Card.Title>
                                <FaQuestion /> Porque precisamos dos seus dados
                            </Card.Title>
                            <Card.Text>
                                Utilizamos o CPF para verificar as oportunidades que mais combinam com você.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={12} className='mb-3'>
                    <Card style={{ backgroundColor: 'transparent' }} bg='border-0 mb-3'>
                        <h6 className='p-2'>Ao Continuar, você:</h6>
                        <Card.Body>
                            {messagesToUser.contractTerms.sort((prev, next) => prev - next.id).map(item => (
                                item?.hiperlink ? (
                                    <Card.Text className='text-left' key={item.id}>
                                        {item.text}  <Link to={'#'} onClick={handleShow} style={{
                                            color: backgroundColor
                                        }} className='fw-bold text-decoration-none'>
                                            Clique aqui
                                        </Link>
                                    </Card.Text>
                                ) : (
                                    <Card.Text className='text-left' key={item.id} >
                                        {item.text} {item.id === 2 && (<FaWhatsapp />)}
                                    </Card.Text>

                                )
                            ))}
                        </Card.Body>
                    </Card>
                </Col>
                <Col className='mb-3' md={12} sm={12}>
                    <div className='d-grid gap-2'>
                        <Button
                            disabled={valid ? false : true}
                            className='d-none d-md-block'
                            variant={`outline-${colorButton}`} size={'lg'} id='button-next'
                            onClick={onSubmit}>
                            Aceitar e Continuar {' '}  <FaArrowRight />
                        </Button>
                        <Button
                            disabled={valid ? false : true}
                            className='rounded-0 fixed-bottom d-block d-md-none'
                            variant={`${colorButton}`} size={'lg'} id='button-next'
                            onClick={onSubmit}>
                            Aceitar e Continuar {' '}  <FaArrowRight />
                        </Button>
                    </div>
                </Col>
            </Row>
            <ModalPolices handleClose={handleClose} show={show} />
        </Form>
    );
};

export default FormDocidentification;
