import React, { useContext, useEffect, useState } from 'react';
import {
    Card, Col, ListGroup, Row,
} from 'react-bootstrap';
import { formContext } from '../../../../context/formcontext';
import PropTypes from 'prop-types';
import { convertToTitleCase, formatCurrency, replaceWords } from '../../../../helpers/stringHandless';
import { opcoesSaque } from '../../../../constants';
import IconBank from '../../../../assets/icons/iconsBank';

const ItemHealthInsurance = ({ item, onClick }) => {
    const { state } = useContext(formContext);
    const [className, setClassName] = useState('text-capitalize text-secondary');
    const { matricula } = state;
    useEffect(() => {
        const controllClassName = () => {
            if (Number(item.matricula) === Number(matricula)) {
                return setClassName('fw-bold text-light text-capitalize');
            }
            return setClassName('fw-bold text-capitalize text-secondary');
        };
        controllClassName();
    }, [matricula]);
    const RenderParcelas = () => {
        if (item.opcoes?.length) {
            const sortItems = item.opcoes.sort((prev, next) => Number(prev) - Number(next.qtdParcelas), 0);
            const lastsortItems = sortItems[0];
            if (!lastsortItems) {
                return <></>;
            }
            const {
                qtdParcelas,
                // vlLimite,
                vlLimiteParcela,
            } = lastsortItems;
            return (
                <>
                    <Col>
                        <Card.Text className={`${replaceWords(className, ['fw-bold', 'text-secondary'])} mb-3 text-left`}>
                            <span className={className}>Quantidade de parcelas: </span> {qtdParcelas}x
                        </Card.Text>
                    </Col>
                    {vlLimiteParcela > 0 ? (

                        <Col>
                            <Card.Text className={`${replaceWords(className, ['fw-bold', 'text-secondary'])} mb-3 text-left`}>
                                <span className={className}>Limite da parcela: </span> {formatCurrency(vlLimiteParcela)}
                            </Card.Text>
                        </Col>
                    ) : ''}
                </>
            );
        }
    };
    return (
        <ListGroup.Item onClick={() => onClick(item)} className={matricula === item.matricula ? 'mb-3 rounded-4 border-2 bg-secondary' : 'mb-3 rounded-4 border-2'} as={'li'} style={{
            cursor: 'pointer',
        }}>
            <Row className='p-3 mb-3'>
                <Col sm={12} md={12}>
                    {item?.nome ? (
                        <Card.Text className={`${replaceWords(className, ['fw-bold', 'text-secondary'])} mb-3 text-left`}>
                            <span className={className}>Convênio para: </span>  {convertToTitleCase(item?.nome)}
                        </Card.Text>
                    ) : ''}
                    <Row>
                        <Col md={12} sm={12}>
                            <Card.Text className={`${replaceWords(className, ['fw-bold', 'text-secondary'])} mb-3 text-left`}>
                                <span className={className}>Valor para saque: </span>
                            </Card.Text>
                        </Col>
                        <Col>
                            <div className={`${replaceWords(className, ['fw-bold', 'text-secondary'])} display-1`}>{formatCurrency(Math.abs(item?.limiteDisponivel))}</div>
                        </Col>
                    </Row>
                    <hr />
                    {item?.opcoes?.length && <RenderParcelas opcoes={item.opcoes} /> && (
                        <Row>
                            <Col md={12} sm={12}>
                                <Card.Text className={`${replaceWords(className, ['fw-bold', 'text-secondary'])} mb-3 text-left`}>
                                    <span className={className}>Parcelas </span>
                                </Card.Text>
                            </Col>
                            <RenderParcelas />
                        </Row>
                    )}
                    <Row>
                        {item?.saqueComplementar ? (
                            <Col md={12} sm={12}>
                                <Card.Text className={`${replaceWords(className, ['fw-bold', 'text-secondary'])} mb-3 text-left`}>
                                    <span className={className}>Tipo de saque: </span> {convertToTitleCase(opcoesSaque.SAQUE_COMPLEMENTAR)}
                                </Card.Text>
                            </Col>
                        ) : (
                            <Col md={12} sm={12}>
                                <Card.Text className={`${replaceWords(className, ['fw-bold', 'text-secondary'])} mb-3 text-left`}>
                                    <span className={className}>Tipo de saque: </span> {convertToTitleCase(opcoesSaque.SAQUE_FACIL)}
                                </Card.Text>
                            </Col>
                        )}
                    </Row>
                    <hr />
                    <Row>
                        <Col md={12} sm={12}>
                            <Card.Text className={`${replaceWords(className, ['fw-bold', 'text-secondary'])} mb-3 text-left`}>
                                <span className={className}>Convênio com o banco </span>
                            </Card.Text>
                        </Col>
                        <Col md={4} sm={4}>
                            <Card.Text className={`${replaceWords(className, ['fw-bold', 'text-secondary'])} mb-3 text-left`}>
                                <span className={className}>Agência: </span> {item.cdAgencia}
                            </Card.Text>
                        </Col>
                        <Col md={4} sm={4}>
                            <Card.Text className={`${replaceWords(className, ['fw-bold', 'text-secondary'])} mb-3 text-left`}>
                                <span className={className}>Código do banco: </span> {item.cdBanco} {<IconBank codigo_bank={item.cdBanco} />}
                            </Card.Text>
                        </Col>
                        <Col md={4} sm={4}>
                            <Card.Text className={`${replaceWords(className, ['fw-bold', 'text-secondary'])} mb-3 text-left`}>
                                <span className={className}>N° da Conta: </span> {item.cdConta}
                            </Card.Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </ListGroup.Item>
    );
};

export default ItemHealthInsurance;
ItemHealthInsurance.propTypes = {
    item: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
};
