import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    Card, Col, Container, Row,
} from 'react-bootstrap';
import ListLoan from '../../../components/list/loan';
import Body from './body';
import { formContext } from '../../../context/formcontext';
import { useNavigate } from 'react-router-dom';
import { dismissAll, handleWarn } from '../../../components/toastfy';
import { Person } from '../../../assets/11ponto11/person-magnifying-glass';
import { cardImage, flipImage } from './style';
import { requestContext } from '../../../context/requestContext';
import { FaArrowRight } from '../../../assets/11ponto11/icons';
import { pathViews } from '../../../constants';
import { CONTACT, NAME_CONTACT } from '../../../config/flavor';

const PeoplePerfil = () => {
    const { state: { cpf, idConvenio, phases, isNotRegistred }, handleForm } = useContext(formContext);
    const { responseConsultaCPF, fetchConsultaLimite } = useContext(requestContext);
    const [items, setItems] = useState(null);
    const navigate = useNavigate();
    const handleWhatsapp = () => {
        const message = `Olá ${NAME_CONTACT}, quero solicitar um cartão benefício CREDCESTA, pode me ajudar`;
        window.open(`https://api.whatsapp.com/send?phone=55${CONTACT}&text=${message}`, 'blank').focus();
    };
    useEffect(() => {
        dismissAll();
    }, []);
    useEffect(() => {
        const controlSetValues = async () => {
            if (responseConsultaCPF?.length) {
                const verifyisNotRegistred = responseConsultaCPF.find(item => item.isNotRegistred);
                setItems(responseConsultaCPF);
                if (verifyisNotRegistred) {
                    handleForm({
                        ...verifyisNotRegistred
                    });
                } else {
                    handleForm({
                        isNotRegistred: false,
                    });
                }
            }
        };
        controlSetValues();
    }, [responseConsultaCPF]);
    useEffect(() => {
        handleForm({
            phases: 2,
            idConvenio: null,
        });
    }, []);
    const onSubmit = async () => {
        window.scrollTo(0, 0);
        if (idConvenio === null || !cpf) {
            return handleWarn({ message: 'Você precisa selecionar pelo menus um convenvio' });
        }
        if (isNotRegistred) {
            return handleWhatsapp();
        }

        const response = await fetchConsultaLimite({
            cpf: cpf,
            id_convenio: idConvenio
        });
        if (response) {
            handleForm({
                phases: phases + 1
            });
            navigate(pathViews.CONVENIOS);
        }
    };
    const RenderButtonSubmit = () => {
        return (
            <div className='d-grid gap-2'>
                <Button className='d-none d-md-block' variant='dark' disabled={idConvenio === null} size='lg' onClick={onSubmit}>
                    {isNotRegistred ? (
                        <>
                            Solicitar Cartão
                            <img src={require('../../../assets/11ponto11/cartao.webp')} style={cardImage} />
                        </>
                    ) : (
                        <>
                            Continuar {' '} <FaArrowRight />
                        </>
                    )}
                </Button>
                <Button className='d-block d-md-none fixed-bottom rounded-0' variant='dark' disabled={idConvenio === null} size='lg' onClick={onSubmit}>
                    {isNotRegistred ? (
                        <>
                            Solicitar Cartão
                            <img src={require('../../../assets/11ponto11/cartao.webp')} style={cardImage} />
                        </>
                    ) : (
                        <>
                            Continuar {' '} <FaArrowRight />
                        </>
                    )}
                </Button>
            </div>
        );
    };
    return (
        <Container fluid className='mb-5'>
            <div className='d-block d-md-none vh-100'>
                <Card className='border-0 rounded'>
                    <Card.Body>
                        <Body />
                        {items ? (
                            <ListLoan items={items} />
                        ) : ''}
                        <RenderButtonSubmit />
                    </Card.Body>
                </Card>
            </div>
            <div className='d-none d-md-block'>
                <Row className='align-items-end'>
                    <Col md={8}>
                        <Card className='border-0 rounded'>
                            <Card.Body>
                                <Body />
                                {items ? (
                                    <ListLoan items={items} />
                                ) : ''}
                                <RenderButtonSubmit />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} >
                        <Person className='img-responsive sticky-top' style={{
                            ...flipImage,
                            width: '100%',

                        }} />
                    </Col>
                </Row>
            </div >
        </Container >
    );
};

export default PeoplePerfil;
