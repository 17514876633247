import React from 'react';
import { Link } from 'react-router-dom';
import { CONTACT, NAME_CONTACT } from '../../../config/flavor';
import { FaWhatsapp } from '../../../assets/11ponto11/icons';

const ButtonWhatsapp = () => {
    const handleWhatsapp = () => {
        const message = `Olá ${NAME_CONTACT} tudo bem? \n Poderia me ajudar?`;
        window.open(`https://api.whatsapp.com/send?phone=55${CONTACT}&text=${message}`, 'blank').focus();
    };
    return (
        <Link to="#" className='d-none d-md-block' onClick={handleWhatsapp}>
            <div
                className='btn-whatsapp'
                title='Precisa de ajuda? Nos chame no Whatsapp'>
                <FaWhatsapp fill={'#ffff'} style={{
                    width: 30,
                    height: 30,
                    color: '#fff'
                }} />
            </div>
        </Link>
    );
};
export default ButtonWhatsapp;